<template>
    <div class="container">
        <div class="headerbar">
            <div class="logo">
                <img src="../../img/logo.svg" alt="" style="width:50px" />
                <img src="../../img/logo1.png" alt="" />
            </div>
            <div class="zhongjian">
                <p>跨域路由热力图</p>
            </div>
        </div>
        <div class="content">
            <div class="secLeft">
                <div class="left1">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>数据流向趋势</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left1Con">
                        <div id="sangji"></div>
                    </div>
                </div>
                <div class="left2">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>算力资源分布情况</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left2Con">
                        <div class="left21">
                            <div class="text">
                                <p>超</p>
                                <span>8.4</span>
                                <p>万个</p>
                            </div>
                            <div class="pp">分布式协同计算点</div>
                        </div>
                        <div class="left21">
                            <div class="text">
                                <span>47.8</span>
                                <p>万架</p>
                            </div>
                            <div class="pp">对外可用IDC机架</div>
                        </div>
                        <div class="left21">
                            <div class="text">
                                <p>超</p>
                                <span>80.4</span>
                                <p>万台</p>
                            </div>
                            <div class="pp">云服务器</div>
                        </div>
                        <div class="left21">
                            <div class="text">
                                <span>9.0</span>
                                <p>EFLOPS</p>
                            </div>
                            <div class="pp">算力规模</div>
                        </div>
                    </div>

                </div>
                <div class="left3">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>网络带宽监控</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left3Con">
                        <div id="zhe"></div>
                    </div>
                </div>
                <div class="left3">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>网络部署图</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left3Con">
                        <img src="../../img/secbg2.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="secMid">
                <div class="midList">
                    <div class="list1">
                        <img src="../../img/sec1.png" alt="">
                        <div class="des">
                            <p>11</p>
                            <span>数联网接入节点（个）</span>
                        </div>
                    </div>
                    <div class="list1">
                        <img src="../../img/sec2.png" alt="">
                        <div class="des">
                            <p>31</p>
                            <span>数据流通交付项目（个）</span>
                        </div>
                    </div>
                    <div class="list1">
                        <img src="../../img/sec3.png" alt="">
                        <div class="des">
                            <p>234</p>
                            <span>上链哈希（区块）</span>
                        </div>
                    </div>
                    <div class="list1">
                        <img src="../../img/sec4.png" alt="">
                        <div class="des">
                            <p>10</p>
                            <span>当前在线节点（个）</span>
                        </div>
                    </div>
                    <div class="list1">
                        <img src="../../img/sec5.png" alt="">
                        <div class="des">
                            <p>78</p>
                            <span>数联网接入数据(TB)</span>
                        </div>
                    </div>
                    <div class="list1">
                        <img src="../../img/sec6.png" alt="">
                        <div class="des">
                            <p>109</p>
                            <span>网络异常告警（次）</span>
                        </div>
                    </div>

                </div>
                <div class="ditu">
                    <div id="china"></div>
                    <!-- <div class="china">
                        <img src="../../img/ditu.svg" alt="">
                    </div> -->
                </div>
                <div class="midDi">
                    <div class="di1">
                        <img src="../../img/secd1.png" alt="">
                        <div class="des">
                            <p>累计发生交易</p>
                            <span>291</span>
                        </div>
                    </div>
                    <div class="di1">
                        <img src="../../img/secd2.png" alt="">
                        <div class="des">
                            <p>累计新增节点</p>
                            <span>456</span>
                        </div>
                    </div>
                    <div class="di1">
                        <img src="../../img/secd3.png" alt="">
                        <div class="des">
                            <p>今日交换数据</p>
                            <span>540</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="secRight">
                <div class="right1">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>网络带宽使用情况</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="right1Con">
                        <div class="quan">
                            <div id="quan1"></div>
                            <div class="des">
                                <p>可用带宽</p>
                            </div>
                        </div>
                        <div class="quan">
                            <div id="quan2"></div>
                            <div class="des">
                                <p>带宽利用率</p>
                            </div>
                        </div>
                        <div class="quan">
                            <div id="quan3"></div>
                            <div class="des">
                                <p>带宽峰值</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right2">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>专网流量趋势</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="right2Con">
                        <div id="zhe2"></div>
                    </div>
                </div>
                <div class="right3">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>最新节点状态</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="right3Con">
                        <vue-seamless-scroll :data="slist" class="seamless-warp" :class-option="defaultOption">
                            <div class="list_c">
                                <div class="c" v-for="(item, index) in slist" :key="index">
                                    <div class="c1">
                                        <img src="../../img/icon2.png" alt="">
                                        <div class="des">
                                            <p>{{ item.name }}</p>
                                            <p>{{ item.des }}</p>
                                        </div>
                                    </div>
                                    <i>{{ item.date }}</i>
                                </div>
                            </div>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>

        <router-link to="/index" class="zuozuo" style="opacity:0.5">
            <img src="../../img/xiangzuo.png" alt="">
        </router-link>
        <router-link to="/third" class="youyou" style="opacity:0.5">
            <img src="../../img/xiangyou.png" alt="">
        </router-link>
    </div>
</template>

<script>
const echarts = require("echarts");
require("../../assets/china");
import 'echarts-liquidfill'
export default {
    data() {
        return {
            secChart1: '',
            secChart2: '',
            secChart3: '',
            secChart4: '',
            secChart5: '',
            secChart6: '',
            dataShuju: {
                value: [80, 120, 90, 170, 141, 160, 100, 110, 80, 120, 90, 170],
                color: '#14E1F8',
                name: '流入带宽流量',
            },
            dataQushi: {
                value: [100, 115, 85, 160, 151, 170, 110, 130, 100, 115, 85, 160],
                color: '#FFE300',
                name: '流出带宽流量',
            },
            shuidata: {
                value: '45Mbps',
                label: "可用宽带",
                color: "#6CC7F0",
                color1: "#6CC7F0",
            },
            shuidata1: {
                value: '40%',
                label: "带宽利用率",
                color: "#AB5DEF",
                color1: "#AB5DEF",
            },
            shuidata2: {
                value: '37Mbps',
                label: "带宽峰值",
                color: "#84DBE6",
                color1: "#84DBE6"
            },
            progress: {
                num: 22.6
            },
            dataShuju2: {
                date: ['10月3日', '10月5日', '10月7日', '10月9日'],
                value: ['65', '75', '85', '65'],
                value1: ['65', '75', '85', '65'],
                color: "#00ffc6",
                name: "合格率",
            },
            slist: [
                {
                    name: '银行节点',
                    des: '完成磐道专网接口注册',
                    date: '11分钟前',
                },
                {
                    name: '政务节点',
                    des: '完成授权数据集资源',
                    date: '1小时前',
                },
                {
                    name: '高速集团节点',
                    des: '完成来源于交易所渠道的工单创建',
                    date: '10小时前',
                },
                {
                    name: '银行节点',
                    des: '完成磐道专网接口注册',
                    date: '11分钟前',
                },
                {
                    name: '政务节点',
                    des: '完成授权数据集资源',
                    date: '1小时前',
                },
                {
                    name: '高速集团节点',
                    des: '完成来源于交易所渠道的工单创建',
                    date: '10小时前',
                },

            ],
        }
    },
    computed: {
        defaultOption() {
            return {
                step: 0.3, // 数值越大速度滚动越快
                limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.getsangji()
        this.getzhe()
        this.getmap()
        this.getzhu()
        this.getshui2(this.shuidata, "quan1")
        this.getshui2(this.shuidata1, "quan2")
        this.getshui2(this.shuidata2, "quan3")
    },
    methods: {
        getsangji() {
            this.secChart1 = echarts.init(document.getElementById('sangji'));
            var option = {
                color: ['#46C4F7', '#46F7B4', '#F7D646'], // 设置节点和边的默认颜色
                grid: {
                    right: '15%',
                    containLabel: true,
                },
                series: {
                    type: 'sankey',
                    layout: 'none',
                    emphasis: {
                        focus: 'adjacency'
                    },
                    nodeWidth: 10,
                    edgeLabel: {
                        show: true,
                        color: '#fff'
                    },

                    data: [
                        {
                            name: '数据产品',
                            value: 100,
                            itemStyle: {
                                color: '#46C4F7'
                            },
                            label: {
                                color: '#fff',
                                borderWidth: 1,
                                borderColor: '#5BADD3',
                                borderRadius: 3,
                                backgroundColor: '#0D3263',
                                padding: [5, 7],
                                fontSize: '11',
                                formatter: function (params) {
                                    return params.data.name + params.data.value;
                                }
                            },
                        },
                        {
                            name: '申请数',
                            value: 200,
                            itemStyle: {
                                color: '#46F7B4'
                            },
                            label: {
                                color: '#fff',
                                borderWidth: 1,
                                borderColor: '#5BADD3',
                                borderRadius: 3,
                                backgroundColor: '#0D3263',
                                padding: [5, 7],
                                fontSize: '11',
                                formatter: function (params) {
                                    return params.data.name + params.data.value;
                                }
                            },
                        },
                        {
                            name: '待审核',
                            value: 20,
                            itemStyle: {
                                color: '#F7D646'
                            },
                            label: {
                                color: '#fff',
                                borderWidth: 1,
                                borderColor: '#5BADD3',
                                borderRadius: 3,
                                backgroundColor: '#0D3263',
                                padding: [5, 7],
                                fontSize: '11',
                                formatter: function (params) {
                                    return params.data.name + params.data.value;
                                }
                            },
                        },
                        {
                            name: '待交付',
                            value: 50,
                            itemStyle: {
                                color: '#F7D646'
                            },
                            label: {
                                color: '#fff',
                                borderWidth: 1,
                                borderColor: '#5BADD3',
                                borderRadius: 3,
                                backgroundColor: '#0D3263',
                                padding: [5, 7],
                                fontSize: '11',
                                formatter: function (params) {
                                    return params.data.name + params.data.value;
                                }
                            },
                        },
                        {
                            name: '交付中',
                            value: 60,
                            itemStyle: {
                                color: '#F7D646'
                            },
                            label: {
                                color: '#fff',
                                borderWidth: 1,
                                borderColor: '#5BADD3',
                                borderRadius: 3,
                                backgroundColor: '#0D3263',
                                padding: [5, 7],
                                fontSize: '11',
                                formatter: function (params) {
                                    return params.data.name + params.data.value;
                                }
                            },
                        },
                        {
                            name: '交付完成',
                            value: 60,
                            itemStyle: {
                                color: '#F7D646'
                            },
                            label: {
                                color: '#fff',
                                borderWidth: 1,
                                borderColor: '#5BADD3',
                                borderRadius: 3,
                                backgroundColor: '#0D3263',
                                padding: [5, 7],
                                fontSize: '11',
                                formatter: function (params) {
                                    return params.data.name + params.data.value;
                                }
                            },
                        }
                    ],
                    links: [
                        {
                            source: '数据产品',
                            target: '申请数',
                            value: 100,
                            edgeLabel: {
                                show: false,
                            },
                            lineStyle: {
                                color: '#144266',
                                opacity: 0.7
                            },
                        },
                        {
                            source: '申请数',
                            target: '待审核',
                            value: 25,
                            edgeLabel: {
                                show: false,
                            },
                            lineStyle: {
                                color: '#154F55',
                                opacity: 0.7
                            },
                        },
                        {
                            source: '申请数',
                            target: '待交付',
                            value: 25,
                            edgeLabel: {
                                show: false,
                            },
                            lineStyle: {
                                color: '#154F55',
                                opacity: 0.7
                            },
                        },
                        {
                            source: '申请数',
                            target: '交付中',
                            value: 25,
                            edgeLabel: {
                                show: false,
                            },
                            lineStyle: {
                                color: '#154F55',
                                opacity: 0.7
                            },
                        },
                        {
                            source: '申请数',
                            target: '交付完成',
                            value: 25,
                            edgeLabel: {
                                show: false,
                            },
                            lineStyle: {
                                color: '#154F55',
                                opacity: 0.7
                            },
                        },
                    ],
                }
            };
            this.secChart1.setOption(option);
        },
        getzhe() {
            this.secChart2 = echarts.init(document.getElementById('zhe'));
            var option = {
                legend: {
                    data: ['流入带宽流量', '流出带宽流量'],
                    right: '5%',
                    top: '5%',
                    textStyle: {
                        color: '#fff',
                        fontSize: '12'
                    },
                    // icon: 'roundRect',
                },
                grid: {
                    left: '1%',
                    right: '4%',
                    bottom: '5%',
                    top: '20%',
                    containLabel: true,
                    // borderColor:'#dce8fe',
                    // borderWidth: 5 , 
                    // show:true,
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: "rgba(2,14,32,0.8)", //设置背景图片 rgba格式
                    color: "white",
                    borderWidth: "3", //边框宽度设置1
                    borderColor: "#019ac7", //设置边框颜色
                    textStyle: {
                        color: "white" //设置文字颜色
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['2:00', '4:00', '6:00', '8:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00', '24:00'],

                    textStyle: {
                        color: '0288b0',
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#035279'
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#043a59',
                            width: 3,
                            type: "solid"
                        }
                    },
                    axisLabel: {//x轴文字的配置
                        show: true,
                        interval: 0, // 强制显示所有刻度
                        textStyle: {
                            color: "#E8EAED",
                            fontSize: '11'
                        }
                    },


                },
                yAxis: {
                    type: 'value',
                    textStyle: {
                        color: '#E8EAED',
                    },
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: '#11d2f0'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#043a59'
                        }
                    },
                    axisLabel: {//x轴文字的配置
                        show: true,
                        textStyle: {
                            color: "#E8EAED",
                            fontSize: '11'
                        }
                    },
                },
                series: [

                    {
                        name: this.dataQushi.name,
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 13,
                        showSymbol: true,
                        data: this.dataQushi.value,
                        itemStyle: {
                            color: this.dataQushi.color,
                            borderColor: '#021c36',
                            borderWidth: 2, borderType: 'solid'
                        },
                        lineStyle: {
                            color: this.dataQushi.color,
                            width: 1
                        },
                        areaStyle: { color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: this.dataQushi.color }, { offset: 1, color: 'transparent' }]), opacity: 0.15 },
                    },
                    {
                        name: this.dataShuju.name,
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 13,
                        showSymbol: true,
                        data: this.dataShuju.value,
                        itemStyle: {
                            color: this.dataShuju.color,
                            borderColor: '#021c36',
                            borderWidth: 2, borderType: 'solid'
                        },
                        lineStyle: {
                            type: 'dashed', // 设置为虚线
                            color: this.dataShuju.color,
                            width: 1
                        },
                        areaStyle: { color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: this.dataShuju.color }, { offset: 1, color: 'transparent' }]), opacity: 0.15 },
                    },

                ],


            };
            this.secChart2.setOption(option);
        },

        getmap() {
            var chartDom = document.getElementById("china");
            let chart = echarts.init(chartDom);
            chartDom.style.width = "800px"; // 设置地图容器的宽度
            chartDom.style.height = "600px"; // 设置地图容器的高度
            chart.setOption({
                geo: {
                    map: "china",
                    top: '8%',
                    z: '12',
                    zoom: 1.2,
                    label: {
                        emphasis: {
                            show: false,
                        },
                    },
                    roam: true,
                    itemStyle: {
                        normal: {
                            areaColor: "#02255E",
                            borderColor: "#43AEF3",
                            borderWidth: '1',
                            shadowColor: 'rgba(167,235,255,0.7)',
                            shadowOffsetX: '0',
                            shadowOffsetY: '1',

                        },
                        emphasis: {
                            areaColor: "#02255E",
                        },
                    },
                    tooltip: {
                        show: true,
                    },

                },
                series: [
                    // {
                    //   type: "map",
                    //   map: "china",
                    //   top: '2%',
                    //   z:'1',
                    // },
                    {
                        type: "effectScatter",
                        coordinateSystem: "geo",

                        data: [
                            {
                                name: "哈尔滨节点",
                                value: [126.668811, 45.80785],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式

                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },

                            {
                                name: "东数西算枢纽节点",
                                value: [110.148038, 42.17005],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式

                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },
                            {
                                name: "东数西算枢纽节点",
                                value: [96.018914, 41.37123],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式
                                label:{
                                    show:true,
                                    position:'left'
                                }
                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },

                            {
                                name: "东数西算枢纽节点",
                                value: [102.789119, 33.244445],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式
                                label:{
                                    show:true,
                                    position:'left'
                                }
                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },

                            {
                                name: "东数西算枢纽节点",
                                value: [101.758871, 27.964011],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式
                                 label:{
                                    show:true,
                                    position:'left'
                                }
                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },
                            {
                                name: "郑州节点",
                                value: [113.753908, 34.013968],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式
                                label:{
                                    show:true,
                                    position:'left',
                                    padding: [8, 10],
                                }
                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },117.948492,35.230665
                            },
                            {
                                name: "东数西算枢纽节点",
                                value: [117.948492, 35.230665],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式

                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },
                            {
                                name: "上海节点",
                                value: [121.77513, 31.274828],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式
                                label:{
                                    show:true,
                                    position:'top'
                                }
                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },
                            {
                                name: "中国数谷数联网枢纽",
                                value: [120.700631,30.72011],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式
                                
                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },
                            {
                                name: "宁波节点",
                                value: [121.422725,29.721516],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式
                                label:{
                                    show:true,
                                    position:'bottom'
                                }
                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",104.040135,23.753841
                                // },
                            },
                            {
                                name: "贵阳节点",
                                value: [106.640604,26.642733],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式

                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },
                            {
                                name: "东数西算枢纽节点",
                                value: [114.121854, 23.278795],
                                symbolSize: 15, // 标记的大小
                                showEffectOn: "render", //散点涟漪的样式

                                // rippleEffect: {
                                //     color: '#FDBC02',
                                //     brushType: "fill",
                                // },
                            },






                        ],
                        symbolSize: function (val) {
                            return val[2] / 10;
                        },
                        showEffectOn: "render",
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        label: {
                            z: 99,
                            distance: 5,
                            normal: {
                                formatter: function (params) {
                                    return params.name; // 返回标签文本
                                },
                                position: function () {
                                    // 随机生成标签的位置
                                    var x = Math.random() * 100; // 在 0 到 100 之间生成随机 x 坐标
                                    var y = Math.random() * 100; // 在 0 到 100 之间生成随机 y 坐标
                                    return [x + '%', y + '%']; // 返回百分比形式的坐标值
                                },
                                show: true,
                                color: '#fff',
                                padding: [8, 30],
                                fontSize: '12',
                                backgroundColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 0,
                                    colorStops: [{
                                        offset: 0, color: 'rgba(0,0,0,0)' // 开始颜色，透明
                                    }, {
                                        offset: 0.3, color: 'rgba(28, 57, 141,0.7)' // 左侧透明
                                    }, {
                                        offset: 0.5, color: '#1C398D' // 中间实体颜色
                                    }, {
                                        offset: 0.7, color: 'rgba(28, 57, 141,0.7)' // 右侧透明
                                    }, {
                                        offset: 1, color: 'rgba(0,0,0,0)' // 结束颜色，透明
                                    }],
                                    globalCoord: false
                                }
                            },
                            emphasis: {
                                color: '#D8C615',
                                // backgroundColor: {
                                //     color:'transparent'
                                // },
                            }
                            // emphasis: {
                            //   formatter: "{b}",
                            //   position: "top",
                            //   show: true,
                            //   color: '#fff',
                            //   padding: [5, 10],
                            //   backgroundColor: {
                            //     image: bba
                            //   },
                            // },

                        },
                        itemStyle: {
                            normal: {
                                color: "#E0CC0F",
                                shadowBlur: 10,
                                shadowColor: "#E0CC0F",
                            },
                            emphasis: {
                                color: "#FFBE00",
                                shadowColor: "#FFBE00",
                            }
                        },
                        zlevel: 1,
                    },
                    {
                        zlevel: 10,
                        type: 'lines',
                        coordinateSystem: 'geo',
                        lineStyle: {
                            color: '#FDE101', // 设置线的颜色为红色，透明度为0.8
                            width: 1, // 设置线的宽度为2
                            type: 'solid', // 设置线的类型为贝塞尔曲线
                            opacity: 0.2, // 设置线的透明度为1
                            curveness: 0.5,
                        },
                        effect: {
                            show: true,
                            roundTrip: true,
                        },
                        data: [
                            { coords: [[126.668811, 45.80785], [110.148038, 42.17005], [96.018914, 41.37123]] },
                            { coords: [[126.668811, 45.80785], [96.018914, 41.37123]] },
                            { coords: [[126.668811, 45.80785], [102.789119, 33.244445]] },
                            { coords: [[126.668811, 45.80785], [101.758871, 27.964011]] },
                            { coords: [[110.148038, 42.17005], [96.018914, 41.37123]] },
                            { coords: [[110.148038, 42.17005], [102.789119, 33.244445]] },
                            { coords: [[110.148038, 42.17005], [101.758871, 27.964011]] },
                            { coords: [[110.148038, 42.17005], [113.753908, 34.013968]] },
                            { coords: [[96.018914, 41.37123], [102.789119, 33.244445]] },
                            { coords: [[96.018914, 41.37123], [117.948492, 35.230665]] },
                            { coords: [[96.018914, 41.37123], [101.758871, 27.964011]] },
                            { coords: [[102.789119, 33.244445], [121.77513, 31.274828]] },
                            { coords: [[102.789119, 33.244445], [120.700631,30.72011]] },

                            { coords: [[101.758871, 27.964011], [101.758871, 27.964011]] },
                            { coords: [[101.758871, 27.964011], [120.700631,30.72011]] },
                            { coords: [[101.758871, 27.964011], [101.758871, 27.964011]] },

                            { coords: [[106.640604,26.642733], [114.121854, 23.278795]] },
                            { coords: [[106.640604,26.642733], [120.700631,30.72011]] },
                            { coords: [[106.640604,26.642733], [101.758871, 27.964011]] },
                            { coords: [[114.121854, 23.278795], [96.018914, 41.37123]] },
                            { coords: [[114.121854, 23.278795], [121.77513, 31.274828]] },


                        ],
                        // 线的配置...
                    }


                ],

            });
        },

        getshui2(data, id) {
            // var _this = this;
            this.secChart4 = echarts.init(document.getElementById(id));
            this.secChart4.setOption({
                // backgroundColor: "#050038",
                title: {
                    text: ``,
                    left: "center",
                    top: "35",
                    textStyle: {
                        show: false,
                        fontWeight: "normal",
                        fontSize: 20,
                        color: "#fff"
                    },
                    // textAlign:'center',
                    subtext: data.value, //副标题
                    subtextStyle: {
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "#fff",
                        fontFamily: "pmzd"

                    }
                },

                series: [{
                    type: "liquidFill", // 图表类型
                    radius: "80%",
                    center: ["50%", "50%"],
                    data: [
                        this.progress.num / 100,
                        this.progress.num / 100,
                        this.progress.num / 100
                    ], // data个数代表波浪数
                    backgroundStyle: {
                        borderWidth: 1,
                        color: "transparent"
                    },
                    label: {
                        normal: {
                            show: false, //不显示label  用副标题代替了
                            formatter: (0.5 * 100).toFixed(0) + "%",
                            textStyle: {
                                fontSize: 38,
                                color: "black"
                            }
                        }
                    },
                    color: [ //color一定要是个数组 ，每一项对应一个波浪，可以给每个波浪单独配置颜色
                        {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1,
                                color: data.color
                            },
                            {
                                offset: 0,
                                color: data.color
                            }
                            ],
                            globalCoord: false
                        },
                        {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1,
                                color: data.color
                            },
                            {
                                offset: 0,
                                color: data.color
                            }
                            ],
                            globalCoord: false
                        },
                        {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1,
                                color: data.color
                            },
                            {
                                offset: 0,
                                color: data.color
                            }
                            ],
                            globalCoord: false
                        }
                    ],
                    outline: {
                        show: false
                    }
                },
                //外层线
                {
                    type: "pie",
                    center: ["50%", "50%"],
                    radius: ["82%", "83%"], //外层线粗细
                    hoverAnimation: false,
                    data: [{
                        name: "",
                        value: 100,
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            color: "#33E2ED"
                        },
                        emphasis: {
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                // color: "#018ab9"
                            }
                        }
                    }]
                },
                //进度线
                {
                    type: "pie",
                    center: ["50%", "50%"],
                    radius: ["80%", "85%"], //进度线粗细
                    hoverAnimation: false,
                    data: [{
                        name: "",
                        value: this.progress.num, //进度
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 2,
                                colorStops: [
                                    // !! 在此添加渐变过程色 !!
                                    {
                                        offset: 0,
                                        color: "#33E2ED"
                                    },
                                    {
                                        offset: 1,
                                        color: "#33E2ED"
                                    }
                                ]
                            }
                        },
                        emphasis: {
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                color: "#33E2ED"
                            }
                        }
                    },
                    {
                        //画剩余的刻度圆环
                        name: "",
                        value: 100 - this.progress.num,
                        itemStyle: {
                            color: "#33E2ED"
                        },
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        },
                        emphasis: {
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                color: "#33E2ED"
                            }
                        }
                    }
                    ]
                }
                ]
            });
        },

        getzhu() {
            this.myChart1 = echarts.init(document.getElementById("zhe2"));
            const data = this.dataShuju2.value;
            var option = {
                title: {
                    show: true,
                    text: "单位:TB",
                    textStyle: {
                        fontSize: 12,
                        color: "#999",
                    },
                    left: '2%', // 居中对齐
                    top: '5%',
                },
                // roam: false, // 禁止滚轮事件
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        shadowStyle: {
                            color: "rgba(0,156,255,0.2)"
                        },
                        z: 1,
                    },
                    padding: [15, 22],
                    backgroundColor: "rgba(0,0,0,0.9)",
                    borderColor: "#01a3ce",
                    borderWidth: 1,
                    textStyle: {
                        fontSize: 16,
                        lineHeight: 32,
                        color: "#ffffff"
                    },
                },

                grid: {
                    x: "10%", //距离左边
                    x2: "10%", //距离右边
                    y: "20%", // 上距离
                    y2: "15%", // 下距离
                },
                xAxis: {
                    axisLabel: {
                        // interval: 1, //横轴信息全部显示
                        rotate: 0, //0度角倾斜显示
                        show: true,
                        textStyle: {
                            color: "#fff",
                            fontSize: 11,
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#325150",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    data: this.dataShuju2.date,
                },
                yAxis: [
                    {
                        type: "value",
                        position: 'left',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: true,
                            lineStyle: {
                                color: "#0187c4",
                                width: 1,
                                opacity: 1
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: 12,
                                color: "#999"
                            },
                            opacity: 0.7,
                            margin: 15,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#3cd1ff",
                                width: 1,
                                opacity: 0.2
                            },
                        },
                    },
                    {
                        type: "value",
                        position: 'right',
                        name: '(%)',
                        nameLocation: 'start',
                        nameTextStyle: {
                            color: '#999',
                            fontSize: 11,
                            padding: [-5, 0, 0, 40],
                        },
                        axisLine: {
                            show: false
                        },
                        min: 0,
                        max: 100,
                        axisTick: {
                            show: true,
                            lineStyle: {
                                color: "#0187c4",
                                width: 1,
                                opacity: 1
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: 12,
                                color: "#999"
                            },
                            opacity: 0.7,
                            margin: 15,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#3cd1ff",
                                width: 1,
                                opacity: 0.2
                            },
                        },
                    },
                ],


                series: [
                    {
                        name: "TB",
                        data: data,
                        type: "bar",
                        barWidth: 14,
                        barGap: "30%",
                        barCategoryGap: 200,
                        yAxisIndex: 0, // 指定使用左侧 Y 轴
                        label: {
                            show: false,
                            position: "top",
                            distance: 3,
                            fontSize: 12,
                            color: '#fff',
                            formatter: function (params) {
                                // 在标签上添加单位
                                return params.value + "%";
                            },
                        },
                        showBackground: false,//开启背景
                        backgroundStyle: {//设置背景样式
                            color: "rgba(255, 255, 255, 0.3)",
                            // barBorderRadius: 50,
                            borderWidth: 64,
                            borderType: 'solid',
                        },
                        itemStyle: {
                            normal: {
                                // barBorderRadius: [2, 2, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    1,
                                    0,
                                    0,
                                    [{
                                        offset: 0,
                                        color: "#467899", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#00B3E9", // 100% 处的颜色
                                    },
                                    ],
                                ),
                            },

                        },
                        // barGap: 0

                    },

                    {
                        name: '流量',
                        type: 'line',
                        // stack: 'Total',
                        smooth: false,
                        symbol: 'circle',
                        symbolSize: 8,
                        showSymbol: false,
                        showAllSymbol: false,
                        yAxisIndex: 0, // 指定使用左侧 Y 轴
                        data: ['70', '80', '90', '70'],
                        label: {
                            normal: {
                                show: false,
                                position: "top",
                                distance: 5,
                                fontSize: 12,
                                color: '#fff',
                            },
                            formatter: function (params) {
                                // 在标签上添加单位
                                return params.value + "%";
                            },
                        },
                        itemStyle: {
                            color: '#31CACD',
                            borderColor: '#fff',
                            borderWidth: 1,
                            borderType: 'solid'
                        },
                        lineStyle: {
                            color: '#31CACD',
                            width: 2
                        },
                        // areaStyle: { color: new echarts.graphic.LinearGradient(0, 0, 0, 3, [{ offset: 0, color: '#31CACD', }, { offset: 1, color: 'transparent' }]), opacity: 0.15 },
                    },

                ],

            };
            this.myChart1.setOption(option);
        },


        handleResize() {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                this.secChart1.resize();
                this.secChart2.resize();
                // this.secChart3.resize();
                this.secChart4.resize();
            }, 200);
        },
    },
}
</script>

<style scoped lang="scss">
.container {
    position: relative;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 0 1%;
    background: url(../../img/bg.png) center no-repeat;
    background-size: 100% 100%;

    .headerbar {
        margin-bottom: calc(100vh * 20 / 1080);
        width: 100%;
        height: calc(100vh * 100 / 1080);
        background: url(../../img/head_bg.png) center no-repeat;
        background-size: 100% 100%;
        position: relative;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            position: absolute;
            left: 0;
            z-index: 999;
            width: auto;
            height: auto;
            display: flex;
            align-items: center;

            img {
                margin-right: 15px;
                width: 130px;
            }
        }

        .zhongjian {
            width: auto;
            height: auto;
            text-align: center;

            p {
                font-size: 2em;
                color: #fff;
            }
        }
    }

    .content {
        position: relative;
        width: 100%;
        height: calc(100vh * 960 / 1080);
        display: flex;
        justify-content: space-between;

        .left1Top {
            width: 100%;
            height: calc(100vh * 40 / 1080);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: url(../../img/top_bg.png) center no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            padding: 0 3%;

            .left1Top1 {
                width: auto;
                height: auto;
                display: flex;
                align-items: center;

                img {
                    width: auto;
                    margin-right: 10px;
                }

                p {
                    font-size: 1.2rem;
                    color: #fff;
                }
            }

            .more {
                width: auto;

                img {
                    width: auto;
                }
            }
        }

        .secLeft {
            position: relative;
            z-index: 9999;
            width: 21%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            padding-bottom: 1%;

            .left1 {
                margin-bottom: calc(100vh * 10 / 1080);
                width: 100%;
                height: calc(100vh * 220 / 1080);

                .left1Con {
                    width: 100%;
                    height: calc(100vh * 180 / 1080);
                    background: #041736;
                    box-sizing: border-box;
                    padding: 3%;
                    padding-left: 0;

                    #sangji {
                        width: 100%;
                        height: 100%;
                    }
                }

            }

            .left2 {
                margin-bottom: calc(100vh * 10 / 1080);
                width: 100%;
                height: calc(100vh * 170 / 1080);

                .left2Con {
                    width: 100%;
                    height: calc(100vh * 130 / 1080);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 2%;
                    background: #01154F;

                    .left21 {
                        width: 25%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        background: url(../../img/secbg1.png) center no-repeat;
                        background-size: 100% 100%;
                        box-sizing: border-box;
                        padding-top: 3%;

                        .text {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 15%;

                            p {
                                font-size: 0.7rem;
                                color: rgba($color: #fff, $alpha: 0.7);
                            }

                            span {
                                margin: 0 0.1rem;
                                color: #fff;
                                font-size: 1rem;
                            }
                        }

                        .pp {
                            font-size: 0.6rem;
                            color: rgba($color: #fff, $alpha: 0.7);
                        }
                    }
                }
            }

            .left3 {
                margin-bottom: calc(100vh * 10 / 1080);
                width: 100%;
                height: calc(100vh * 250 / 1080);
                background: #041838;

                .left3Con {
                    width: 100%;
                    height: calc(100vh * 200 / 1080);
                    background: #041838;

                    #zhe {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        margin-top: 3%;
                        width: 95%;
                        height: 95%;
                        object-fit: cover;
                    }
                }
            }
        }

        .secMid {
            position: relative;
            width: 56%;
            height: 100%;

            .midList {
                width: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .list1 {
                    margin-bottom: calc(100vh * 10 / 1080);
                    width: 31%;
                    height: calc(100vh * 95 / 1080);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 4%;
                    background: url(../../img/secbg3.png) center no-repeat;
                    background-size: 100% 100%;

                    img {
                        width: auto;
                    }

                    .des {
                        width: 70%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;

                        p {
                            font-size: 1.3rem;
                            font-weight: bold;
                            color: #fff;
                        }

                        span {
                            margin-top: 5px;
                            color: #fff;
                            font-size: 0.9rem;
                        }
                    }
                }
            }

            .ditu {
                width: 100%;
                height: calc(100vh * 580 / 1080);

                #china {
                    width: 100%;
                    height: 100%;
                }

                .china {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .midDi {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                .di1 {
                    margin: 0 15px;
                    width: 205px;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 2%;
                    justify-content: space-between;
                    background: #02235A;

                    img {
                        width: auto;
                    }

                    .des {
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                        justify-content: space-between;

                        p {
                            font-size: 1rem;
                            color: rgba($color: #fff, $alpha: 0.7);
                        }

                        span {
                            margin-top: 3px;
                            font-size: 1.4rem;
                            color: #12A3FF;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .secRight {
            width: 21%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            padding-bottom: 1%;
            position: relative;
            z-index: 9999;

            .right1 {
                margin-bottom: calc(100vh * 15 / 1080);
                width: 100%;
                height: calc(100vh * 300 / 1080);

                .right1Con {
                    width: 100%;
                    height: calc(100vh * 260 / 1080);
                    background: #041838;
                    box-sizing: border-box;
                    padding: 0 3%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .quan {
                        width: 31%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: url(../../img/quanBg.png) center calc(100vh * 42 / 1080) no-repeat;
                        background-size: 100%;

                        #quan1 {
                            width: 100%;
                            height: calc(100vh * 130 / 1080);
                        }

                        #quan2 {
                            width: 100%;
                            height: calc(100vh * 130 / 1080);
                        }

                        #quan3 {
                            width: 100%;
                            height: calc(100vh * 130 / 1080);
                        }

                        .des {
                            margin-top: calc(100vh * 20 / 1080);
                            width: 90px;
                            height: 25px;
                            background: url(../../img/secbg4.png) center no-repeat;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;

                            p {
                                font-size: 0.7rem;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .right2 {
                margin-bottom: calc(100vh * 15 / 1080);
                width: 100%;
                height: calc(100vh * 300 / 1080);

                .right2Con {
                    width: 100%;
                    height: calc(100vh * 260 / 1080);
                    background: #041838;
                    box-sizing: border-box;
                    padding: 0 3%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    #zhe2 {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .right3 {
                margin-bottom: calc(100vh * 15 / 1080);
                width: 100%;
                height: calc(100vh * 300 / 1080);

                .right3Con {
                    width: 100%;
                    height: calc(100vh * 260 / 1080);
                    background: #041838;
                    box-sizing: border-box;
                    padding: 0 3%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .seamless-warp {
                        width: 100%;
                        height: calc(100vh * 260 / 1080);
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            left: 4%;
                            top: 0%;
                            z-index: 7;
                            width: 2px;
                            height: 100%;
                            border-right: 2px dashed #065BB7;
                        }

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .list_c {
                            margin-bottom: calc(100vh * 170 / 1080);
                            position: relative;
                            width: 100%;
                            height: calc(100vh * 260 / 1080);
                            margin-left: 10.5%;
                            //display: flex;
                            //flex-direction: column;
                            //align-items: flex-end;


                            .c {
                                position: relative;
                                margin-bottom: calc(100vh * 10 / 1080);
                                width: 90%;
                                height: calc(100vh * 75 / 1080);
                                display: flex;
                                align-items: baseline;
                                justify-content: space-between;
                                box-sizing: border-box;
                                padding: 1% 4%;
                                background: #063888;

                                // &:nth-child(3n) {
                                //     margin-bottom: calc(100vh * 25 / 1080);
                                // }

                                &::after {
                                    content: '';
                                    position: absolute;
                                    left: -10%;
                                    top: 28%;
                                    width: calc(100vh * 30 / 1080);
                                    height: calc(100vh * 30 / 1080);
                                    z-index: 999;
                                    background: url(../../img/qiuqiu.png) center no-repeat;
                                    background-size: contain;
                                }


                                .c1 {
                                    width: 81%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;

                                    img {
                                        margin-bottom: 10px;
                                        margin-right: 10px;
                                        width: auto;
                                    }

                                    .des {
                                        margin-left: 10px;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: baseline;
                                        justify-content: space-around;
                                        width: 75%;
                                        height: 100%;

                                        p {
                                            width: 100%;
                                            font-size: 0.9REM;
                                            color: #fff;
                                            text-align: left;

                                            &:nth-child(2) {
                                                font-size: 0.8rem;
                                                color: #93B3E8
                                            }
                                        }
                                    }
                                }

                                i {
                                    font-style: normal;
                                    font-size: 0.8rem;
                                    color: #12A3FF;
                                }


                            }
                        }
                    }
                }
            }
        }
    }

}
</style>