<template>
  <div class="dongtu">
    <div class="dtLeft">
      <div class="left_top">
        <div class="top">
          <div class="top1">
            <div class="topDes">
              <!-- <p>累计输入量:981TB</p> -->
              <img src="../img/fouth/t1.png" alt="" />
              <span>开源数据集</span>
            </div>
            <div class="topDes">
              <!-- <p>累计输入量:981TB</p> -->
              <img src="../img/fouth/t2.png" alt="" />
              <span>行业数据集</span>
            </div>
          </div>
          <div class="top2">
            <img src="../img/fouth/arrow_b.svg" alt="" />
            <p>数联猫接入点</p>
            <img class="two" src="../img/fouth/arrow_b.svg" alt="" />
          </div>
          <div class="top3">
            <img src="../img/fouth/t1_1.png" alt="" />
            <img src="../img/fouth/t1_1.png" alt="" />
            <img src="../img/fouth/t1_1.png" alt="" />
          </div>
        </div>
        <div class="top_mid">
          <div class="topMid1">
            <p>数据合规流通证书中心</p>
            <img class="biao" src="../img/fouth/topmid.png" alt="" />
            <span>证书分发</span>
            <img src="../img/fouth/arrow_b.svg" alt="" />
          </div>
        </div>
        <div class="top_mid">
          <div class="topMid1">
            <p>数据交易场所</p>
            <img class="biao2" src="../img/fouth/topright1.png" alt="" />
            <span>数据产品挂牌</span>
            <img src="../img/fouth/arrow_b.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="left_mid">
        <div class="midOne">
          <div class="oneDes">
            <p>数据面</p>
          </div>
          <div class="mian"></div>
        </div>
        <div class="midTwo">
          <div class="Two1">
            <!-- <p>数据量即时流量：500MB/s</p> -->
            <span>数联网枢纽</span>
            <img src="../img/fouth/bbb.png" alt="" />
          </div>
          <div class="zhuan">
            <img src="../img/fouth/arrow_z.svg" alt="" />
          </div>
          <div class="Two2">
            <div class="Three1_1">
              <p>合规认证</p>
              <img src="../img/fouth/three1.png" alt="" />
            </div>
            <span>原始数据授权汇聚</span>
            <img class="ddd" src="../img/fouth/arrow_b.svg" alt="" />
            <div class="Two2_1">
              <img src="../img/fouth/three1_1.png" alt="" />
              <div class="ms">
                <p>大模型</p>
                <p>语料库</p>
              </div>
            </div>
          </div>
          <div class="Two3">
            <div class="Two3_t">
              <img class="bbb" src="../img/fouth/arrow_r.svg" alt="" />
              <div class="Two3_des">
                <p>二次加工处理</p>
                <img src="../img/fouth/two3.png" alt="" />
              </div>
            </div>
            <div class="Two3_b">
              <img src="../img/fouth/arrow_z1.svg" alt="" />
              <p>加工数据虚拟汇聚</p>
            </div>
            <div class="Two3_d">
              <img src="../img/fouth/two3_1.png" alt="" />
              <div class="ms">
                <p>开源/自研</p>
                <p>基础大模型库</p>
              </div>
            </div>
          </div>
          <div class="Two4">
            <div class="jiantou">
              <img class="jiantou" src="../img/fouth/arrow_r.svg" alt="" />
            </div>
            <div class="Two4_1">
              <div class="Two4_des">
                <p>计费交易</p>
                <img src="../img/fouth/two4.png" alt="" />
              </div>
              <img class="eee" src="../img/fouth/arrow_t.svg" alt="" />
              <div class="two4_2">
                <img src="../img/fouth/two4_1.png" alt="" />
                <div class="ms">
                  <p>行业</p>
                  <p>大模型库</p>
                </div>
                <!-- <span>查出模型数量：3个</span> -->
              </div>
            </div>
          </div>
          <div class="Two5">
            <div class="Two5_1">
              <p>存证</p>
              <img src="../img/fouth/zhi.png" alt="" />
            </div>
            <div class="Two5_1">
              <p>追溯</p>
              <img src="../img/fouth/zhi.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="left_dddd">
        <div class="midOne">
          <div class="oneDes">
            <p>算力面</p>
          </div>
          <div class="mian"></div>
        </div>
        <div class="dddTwo">
          <div class="dddtwo1">
            <div class="jian">
              <img src="../img/fouth/arrow_z2.svg" alt="" />
            </div>
            <div class="des">
              <p>语料供给</p>
            </div>
            <div class="ms">
              <div class="ms1">
                <p>语料</p>
                <p>加载和预处理</p>
              </div>
              <img src="../img/fouth/ddd1.png" alt="" />
              <div class="ms2">
                <!-- <span>输入数据量：90B</span>
                <span>词汇表长度：10B个token</span>
                <span>本地算力平均占用率：70%</span> -->
              </div>
            </div>
          </div>
          <div class="dddtwo2">
            <div class="jian">
              <img src="../img/fouth/arrow_z2.svg" alt="" />
            </div>
            <div class="ms">
              <div class="ms1">
                <p>基础大模型</p>
                <p>调优对齐</p>
              </div>
              <div class="des">
                <p>基础大模型加载</p>
              </div>
              <img src="../img/fouth/ddd2.png" alt="" />
              <div class="ms3">
                <p>LoRA</p>
                <p>P-tuning</p>
                <p>........</p>
              </div>
            </div>
            <div class="dibujian">
              <img src="../img/fouth/arrow_r.svg" alt="" />
            </div>
          </div>
          <div class="dddtwo3">
            <div class="jian">
              <img src="../img/fouth/arrow_t1.svg" alt="" />
            </div>
            <div class="ms">
              <div class="ms1">
                <p>大模型</p>
                <p>推理</p>
              </div>
              <div class="des">
                <p>行业大模型部署</p>
              </div>
              <img src="../img/fouth/ddd2.png" alt="" />
              <div class="ms2">
                <!-- <span>平均速率：生成32 token/s</span>
                <span>GPU平均占用率：20%</span> -->
              </div>
            </div>
            <div class="dibujian">
              <img src="../img/fouth/arrow_r1.svg" alt="" />
            </div>
          </div>
          <div class="Two5">
            <div class="Two5_1">
              <p>存证</p>
              <img src="../img/fouth/zhi.png" alt="" />
            </div>
            <div class="Two5_1">
              <p>追溯</p>
              <img src="../img/fouth/zhi.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dtRight">
      
        <div class="righttt">
          <p>区块链</p>
          <img src="../img/fouth/rightttt.png" alt="">
        </div>
        <img src="../img/fouth/arrow_t.svg" alt="">
        <img v-for="(item,index) in imglist" :key="index" src="../img/fouth/rrr.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "dongtuVue",
  data() {
    return {
      imglist: [1, 2, 3, 4, 5,6,7,8]
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dongtu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes moveUpDown {
        0%,100% {
            top: 0; /* 开始位置 */
        }
        50% {
            top: 15px; /* 开始位置 */
        }
    }
    @keyframes moveleft {
        0%,100% {
            left: 0; /* 开始位置 */
        }
        50% {
            left: 10px; /* 开始位置 */
        }
    }
    @keyframes moveleft1 {
        0%,100% {
            left: -50%; /* 开始位置 */
        }
        50% {
            left: -60%; /* 开始位置 */
        }
    }
    @keyframes moveleft2 {
        0%,100% {
            left: -140%; /* 开始位置 */
        }
        50% {
            left: -160%; /* 开始位置 */
        }
    }
    @keyframes breathe {
      0% { opacity: 0.6; }
      50% { opacity: 1; }
      100% { opacity: 0.6; }
    }
  .dtLeft {
    width: 80%;
    height: 100%;
    .left_top {
      position: relative;
      z-index: 999;
      width: 100%;
      height: calc(100vh * 260 / 1080);
      display: flex;
      //   justify-content: space-around;
      .top {
        margin: 0% 8%;
        margin-right: 6%;
        width: 16%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .top1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .topDes {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:nth-child(2){
              img{
                animation: breathe 3s infinite; /* 设置动画 */
              }
            }
            p {
              color: #6cf603;
              font-size: 0.4vw;
            }
            img {
              position: relative;
              width: auto;
              height: auto;
              animation: breathe 4s infinite; /* 设置动画 */
            }
            span {
              padding: 5px 15px;
              border: 1px solid #fff;
              font-size: 0.5vw;
              color: #fff;
            }
          }
        }
        .top2 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          img {
            position: relative;
            margin-left: 15%;
            width: auto;
            //animation: moveUpDown 2.5s infinite; /* 设置动画 */
          }
          .two {
            margin-left: 0;
            margin-right: 15%;
          }
          p {
            font-size: 0.6vw;
            color: #fff;
            padding: 5px 10px;
            background: #2467f0;
            white-space: nowrap;
          }
        }
        .top3 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .top_mid {
        margin-left: 1%;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:last-child {
          margin-left: 10%;
        }
        .topMid1 {
          position: relative;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .biao{
            position: relative;
            animation: breathe 4s infinite; /* 设置动画 */
          }
          .biao2{
            position: relative;
            animation: breathe 3s infinite; /* 设置动画 */
          }
          img {
            position: relative;
            width: auto;
            height: auto;
          }
          p {
            padding: 5px 10px;
            background: #d9924d;
            color: #fff;
          }
          span {
            position: absolute;
            z-index: 999;
            bottom: 20%;
            font-size: 0.5vw;
            color: #fff;
            background: rgba($color: #000, $alpha: 0.7);
          }
        }
      }
    }
    .left_mid {
      width: 100%;
      height: calc(100vh * 300 / 1080);
      margin-top: calc(100vh * -40 / 1080);
      display: flex;
      position: relative;
      z-index: 9999;
      .midOne {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        .oneDes {
          width: 45px;
          height: 100%;
          background-color: #2467f0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p {
            font-size: 1.3vw;
            color: #fff;
          }
        }
        .mian {
          width: 80%;
          height: 100%;
          background: rgba($color: #fff, $alpha: 0.2);
        }
      }
      .midTwo {
        position: absolute;
        top: 5%;
        left: 0%;
        width: 80%;
        height: 100%;
        display: flex;
        .Two1 {
          margin-top: calc(100vh * 50 / 1080);
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            font-size: 0.4vw;
            color: #6cf603;
          }
          span {
            z-index: 999;
            margin-top: calc(100vh * 10 / 1080);
            font-size: 0.6vw;
            color: #fff;
            padding: 5px 10px;
            background: #d9924d;
          }
          img {
            margin-top: calc(100vh * -80 / 1080);
            width: auto;
            position: relative;
            animation: breathe 3s infinite; /* 设置动画 */

          }
        }
        .zhuan {
          margin-right: 0%;
          margin-top: 5%;
          margin-left: -15%;
          width: auto;
          img {
            width: auto;
          }
        }
        .Two2 {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .Three1_1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              font-size: 0.6vw;
              color: #fff;
              background: #2467f0;
              padding: 3px 10px;
              white-space: nowrap;
            }
            img {
              width: auto;
              animation: breathe 4s infinite; /* 设置动画 */
            }
          }
          span {
            font-size: 0.5vw;
            padding: 2px;
            background: rgba($color: #000, $alpha: 0.7);
            position: absolute;
            top: 47%;
            color: #fff;
            white-space: nowrap;
            z-index: 99999;
            // animation: moveUpDown 2s infinite; 
          }
          img {
            width: auto;
          }
          .ddd{
            position: relative;
            //animation: moveUpDown 2s infinite; 
          }
          .Two2_1 {
            margin-top: -27%;
            position: relative;
            width: auto;
            height: auto;
            img{
              animation: breathe 3s infinite; /* 设置动画 */
            }
            .ms {
              position: absolute;
              bottom: 0px;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 3px 10px;
              // height: 30px;
              background: rgba($color: #000, $alpha: 0.7);
              border: 1px solid #fff;
              // animation: moveUpDown 3s infinite; 
              p {
                font-size: 0.5vw;
                color: #fff;
              }
            }
          }
        }
        .Two3 {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: auto;
          .Two3_t {
            display: flex;
            img {
              //margin-top: 3%;
            }
            .bbb{
              position: relative;
              //animation: moveleft 3s infinite; /* 设置动画 */

            }
            .Two3_des {
              margin-left: 12%;
              display: flex;
              flex-direction: column;
              align-items: center;
              p {
                font-size: 0.6vw;
                color: #fff;
                background: #2467f0;
                padding: 3px 10px;
                white-space: nowrap;
              }
              img {
                width: auto;
                animation: breathe 3s infinite; /* 设置动画 */
              }
            }
          }
          .Two3_b {
            position: relative;
            margin-top: 5%;
            img {
              width: 90%;
            }
            p {
              padding: 3px;
              font-size: 0.5vw;
              background: rgba($color: #000, $alpha: 0.7);
              color: #fff;
              position: absolute;
              top: 15%;
              left: 25%;
            }
          }
          .Two3_d {
            margin-top: -52%;
            margin-left: 55%;
            position: relative;
            width: auto;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              margin-left: 55%;
              position: relative;
              animation: breathe 3s infinite; /* 设置动画 */
            }
            .ms {
              position: absolute;
              bottom: -15%;
              left: 25%;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 3px 10px;
              background: rgba($color: #000, $alpha: 0.7);
              border: 1px solid #fff;
              p {
                font-size: 0.5vw;
                color: #fff;
                white-space: nowrap;
              }
            }
          }
        }
        .Two4 {
          margin-right: 15%;
          display: flex;
          // align-items: baseline;
          img {
            width: auto;
          }
          .jiantou {
            margin-right: 21%;
            margin-top: 25%;
            margin-left: 10%;
            position: relative;
            //animation: moveleft 3s infinite; 
          }
          .Two4_1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            .Two4_des{
              p {
                font-size: 0.6vw;
                color: #fff;
                background: #2467f0;
                padding: 3px 10px;
                white-space: nowrap;
              }
              img{
                margin-top: 10px;
                animation: breathe 4s infinite; /* 设置动画 */
              }
            }
            
            .eee{
              position: relative;
              //@erroranimation: moveUpDown 2s infinite;
            }
            .bbb{
              animation: breathe 3s infinite;
            }
          }
          .two4_2 {
            margin-top: -78%;
            margin-left: 15%;
            position: relative;
            width: auto;
            height: auto;
            img{
              animation: breathe 3s infinite;
            }
            span {
              font-size: 0.5vw;
              padding: 2px;
              background: rgba($color: #000, $alpha: 0.7);
              position: absolute;
              top: -47%;
              left: 0;
              color: #fff;
              white-space: nowrap;
            }
            .ms {
              position: absolute;
              bottom: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 3px 10px;
              background: rgba($color: #000, $alpha: 0.7);
              border: 1px solid #fff;
              p {
                font-size: 0.5vw;
                color: #fff;
                white-space: nowrap;
              }
            }
          }
        }
        .Two5 {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;
          height: auto;
          justify-content: center;
          
          .Two5_1 {
            display: flex;
            align-items: center;
            margin: 15% 0;
            &:nth-child(2){
              img{
                transform: rotate(180deg);
                position: relative;
                animation: moveleft 2s infinite; 
              }
            }
            img{
              margin-left: 5%;
              position: relative;
               animation: moveleft 3s infinite; 
            }
            p {
              font-size: 1vw;
              color: #fff;
              background: #2467f0;
              padding: 5px;
              width: 40px;
              height: auto;
            }
          }
        }
      }
    }
    .left_dddd {
      width: 100%;
      height: calc(100vh * 300 / 1080);
      margin-top: calc(100vh * 20 / 1080);
      display: flex;
      position: relative;
      .midOne {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        .oneDes {
          width: 45px;
          height: 100%;
          background-color: #2467f0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p {
            font-size: 1.3vw;
            color: #fff;
          }
        }
        .mian {
          width: 80%;
          height: 100%;
          background: rgba($color: #fff, $alpha: 0.2);
        }
      }
      .dddTwo {
        position: absolute;
        top: -8%;
        left: 27%;
        width: 72%;
        height: 100%;
        // display: flex;
        // box-sizing: border-box;
        .dddtwo1 {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          float: left;
          .jian {
            margin-left: 8%;
            width: auto;
            img {
              width: 70%;
            }
          }
          .des{
            position: absolute;
            top: 3%;
            left: 30%;
            padding: 3px 10px;
            background: rgba($color: #000, $alpha: 0.7);
            box-sizing: border-box;
            //border: 1px solid #fff;
            p{
              color: #fff;
              font-size: 0.5vw;
            }
          }


          .ms {
            display: flex;
            flex-direction: column;
            margin-top: -25%;
            .ms1 {
              display: flex;
              flex-direction: column;
              align-items: center;
              background: #2467f0;
              padding: 3px 7px;
              width: 4vw;
              p {
                color: #fff;
                font-size: 0.6vw;
              }
            }
            img {
              margin-top: 10%;
              margin-left: 5%;
              width: 92%;
              animation: breathe 2.5s infinite; /* 设置动画 */
            }
            .ms2 {
              width: auto;
              display: flex;
              flex-direction: column;
              align-items: baseline;
              span {
                font-size: 0.6vw;
                color: #6cf603;
                white-space: nowrap;
              }
            }
          }
        }
        .dddtwo2 {
          position: relative;
          float: left;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .jian {
            margin-left: 8%;
            width: auto;
            img {
              width: 70%;
            }
          }
          .des{
            position: absolute;
            top: 4%;
            left: 30%;
            padding: 3px 10px;
            background: rgba($color: #000, $alpha: 0.7);
            box-sizing: border-box;
            //border: 1px solid #fff;
            p{
              color: #fff;
              font-size: 0.5vw;
            }
          }
          .ms {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -25%;
            .ms1 {
              display: flex;
              flex-direction: column;
              align-items: center;
              background: #2467f0;
              padding: 3px 7px;
              width: 4vw;
              p {
                color: #fff;
                font-size: 0.6vw;
              }
            }
            img {
              margin-top: 10%;
              width:70%;
              animation: breathe 4s infinite; /* 设置动画 */
            }
            .ms2 {
              width: auto;
              display: flex;
              flex-direction: column;
              align-items: baseline;
              span {
                font-size: 0.6vw;
                color: #6cf603;
                white-space: nowrap;
              }
            }
            .ms3{
              width: 80px;
              height: auto;
              display: flex;
              flex-direction: column;
              align-items: baseline;
              position: absolute;
              right: -3%;
              top:35%;
              z-index: 999;
              p{
                padding: 4px 10px;
                background: #2467f0;
                color: #fff;
                font-size: 0.4vw;
                margin-bottom: 13%;
              }
            }
          }
          .dibujian {
            position: absolute;
            bottom: 30%;
            left: -50%;
            // position: relative;
            //animation: moveleft1 3s infinite; /* 设置动画 */
            .ms {
              display: flex;
              flex-direction: column;
              margin-top: -25%;
              .ms1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                background: #2467f0;
                padding: 3px 7px;
                width: 4vw;
                p {
                  color: #fff;
                  font-size: 0.6vw;
                }
              }
              img {
                width: auto;
                animation: breathe 3s infinite; /* 设置动画 */
              }
              .ms2 {
                width: auto;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                span {
                  font-size: 0.6vw;
                  color: #6cf603;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .dddtwo3 {
          float: left;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 13%;
          .jian {
            width: 100%;
            img {
              width: 11%;
            }
          }
          .ms {
            display: flex;
            flex-direction: column;
            margin-top: -25%;
            align-items: center;
            .ms1 {
              display: flex;
              flex-direction: column;
              align-items: center;
              background: #2467f0;
              padding: 3px 7px;
              width: 4vw;
              p {
                color: #fff;
                font-size: 0.6vw;
              }
            }
            .des{
              position: absolute;
              top: 6%;
              left: 0%;
              padding: 3px 10px;
              background: rgba($color: #000, $alpha: 0.7);
              box-sizing: border-box;
              //border: 1px solid #fff;
              p{
                color: #fff;
                font-size: 0.5vw;
                white-space: nowrap;
              }
            }
            img {
              width: 70%;
              animation: breathe 4s infinite; /* 设置动画 */
            }
            .ms2 {
              width: auto;
              display: flex;
              flex-direction: column;
              align-items: baseline;
              span {
                font-size: 0.6vw;
                color: #6cf603;
                white-space: nowrap;
              }
            }
          }

          .dibujian {
            position: absolute;
            bottom: 28%;
            left: -240%;
            //animation: moveleft2 3s infinite; /* 设置动画 */
            img {
              width: 87%;
            }
            .ms {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: -25%;
              .ms1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                background: #2467f0;
                padding: 3px 7px;
                width: 4vw;
                p {
                  color: #fff;
                  font-size: 0.6vw;
                }
              }
              img {
                width: 100%;
                animation: breathe 3s infinite; /* 设置动画 */
              }
              .ms2 {
                width: auto;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                span {
                  font-size: 0.6vw;
                  color: #6cf603;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .Two5 {
          float: left;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;
          height: auto;
          justify-content: center;
          width: 15%;
          margin-left: 9%;
          margin-top: 5%;

          .Two5_1 {
            display: flex;
            align-items: center;
            margin: 15% 0;
            &:nth-child(2){
              img{
                transform: rotate(180deg);
                position: relative;
                animation: moveleft 2s infinite; 
              }
            }
            img{
              margin-left: 5%;
              position: relative;
               animation: moveleft 3s infinite; 
            }
            p {
              font-size: 1vw;
              color: #fff;
              background: #2467f0;
              padding: 5px;
              width: 40px;
              height: auto;
            }
          }
        }
      }
    }
  }
  .dtRight {
    margin-left: -8%;
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      color: #fff;
      background: #d9924d;
      padding: 3px 10px;
    }
    img{
      width: auto;
    }
  }
}
</style>