<template>
  <div class="container" ref="ccccc">
    <!-- 头部 -->
    <div class="headerbar">
      <div class="logo">
        <img src="../../img/logo.svg" alt="" style="width: 50px" />
        <img src="../../img/logo1.png" alt="" />
      </div>
      <div class="zhongjian">
        <p>数据资源地图</p>
      </div>
    </div>
    <!-- 选择横条 -->
    <div class="xuanze">
      <div class="xz1">
        <p>分类标准:</p>
        <div class="zlist">
          <el-select v-model="value" placeholder="金融行业数据分类">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>数据分类:</p>
        <div class="zlist">
          <el-select v-model="value1" placeholder="请选择">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>敏感分级:</p>
        <div class="zlist">
          <el-select v-model="value2" placeholder="请选择">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>接入方式:</p>
        <div class="zlist">
          <el-select v-model="value3" placeholder="请选择">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>资源名称:</p>
        <div class="zlist">
          <el-select v-model="value4" placeholder="请选择">
            <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz2">
        <img src="../../img/jiahao.png" alt="" />
      </div>
      <div class="xz2">
        <img src="../../img/caidan.png" alt="" />
      </div>
    </div>
    <!-- 整体内容部分 -->
    <div class="content">
      <!-- 左边内容 -->
      <div class="conLeft">
        <!-- 第一个展示 -->
        <div class="left1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="" />
              <p>合作伙伴数(个)</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="" />
            </div>
          </div>
          <div class="left1Con">
            <div class="left1Con1">
              <img src="../../img/icon1.png" alt="" />
              <p>150</p>
            </div>
            <div class="left1Con2">
              <div class="list1">
                <span>同比</span>
                <p>89%</p>
              </div>
              <div class="list1">
                <span>环比</span>
                <p>14%</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 第二个展示 -->
        <div class="left1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="" />
              <p>数据资源总量(个)</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="" />
            </div>
          </div>
          <div class="left1Con">
            <div class="left1Con1">
              <img src="../../img/icon1.png" alt="" />
              <p>5262</p>
            </div>
            <div class="left1Con2">
              <div class="list1">
                <span>同比</span>
                <p>151%</p>
              </div>
              <div class="list1">
                <span>环比</span>
                <p>26%</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 第三个展示 -->
        <div class="left2">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="" />
              <p>数据资源行业分布</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="" />
            </div>
          </div>
          <div class="left2Con">
            <div id="echarts"></div>
          </div>
        </div>
        <!-- 第四个展示 -->
        <div class="left2">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="" />
              <p>数据类型分布</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="" />
            </div>
          </div>
          <div class="left2Con">
            <div id="echarts1"></div>
          </div>
        </div>
      </div>
      <!-- 中间内容 -->
      <div class="conMid">
        <div class="midTop">
          <el-input placeholder="请输入关键词搜索，例如“工控设备参数”" v-model="input3" class="input-with-select">
            <el-button slot="append" icon="el-icon-search">搜索</el-button>
          </el-input>
        </div>
        <div class="midC">
          <canvas ref="myCanvas" width="1000" height="650" id="myCanvas"></canvas>
          <!-- <div ref="canvasContainer" style="width: 90%; height: 90%"></div> -->
          <!-- <svg :width="width" :height="height">
            <line
              v-for="(connection, index) in connections"
              :key="index"
              :x1="connection.start.x"
              :y1="connection.start.y"
              :x2="connection.end.x"
              :y2="connection.end.y"
              stroke="#00946D"
              stroke-width="1"
              stroke-dasharray="5,5"
            />
            <circle
              v-for="(point, index) in points"
              :key="index"
              :cx="point.x"
              :cy="point.y"
              r="15"
              fill="red"
            />
          </svg> -->
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="conRight">
        <div class="right1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="" />
              <p>数据资源访问TOP5</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="" />
            </div>
          </div>
          <div class="right1Con">
            <div class="list1">
              <p>金融反欺诈</p>
              <el-progress :percentage="percentage1" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor"></el-progress>
              <span>66次</span>
            </div>
            <div class="list1">
              <p>信用评分</p>
              <el-progress :percentage="percentage2" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor1"></el-progress>
              <span>61次</span>
            </div>
            <div class="list1">
              <p>营销接受度</p>
              <el-progress :percentage="percentage3" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor2"></el-progress>
              <span>55次</span>
            </div>
            <div class="list1">
              <p>用户真实性</p>
              <el-progress :percentage="percentage4" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor3"></el-progress>
              <span>31次</span>
            </div>
            <div class="list1">
              <p>区域画像</p>
              <el-progress :percentage="percentage5" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor4"></el-progress>
              <span>22次</span>
            </div>
          </div>
        </div>
        <div class="right1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="" />
              <p>行业月度访问TOP5</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="" />
            </div>
          </div>
          <div class="right1Con">
            <div class="list1">
              <p>金融行业</p>
              <el-progress :percentage="percentage6" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor"></el-progress>
              <span>69次</span>
            </div>
            <div class="list1">
              <p>通信行业</p>
              <el-progress :percentage="percentage7" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor1"></el-progress>
              <span>54次</span>
            </div>
            <div class="list1">
              <p>工业制造</p>
              <el-progress :percentage="percentage8" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor2"></el-progress>
              <span>45次</span>
            </div>
            <div class="list1">
              <p>公共数据</p>
              <el-progress :percentage="percentage9" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor3"></el-progress>
              <span>34次</span>
            </div>
            <div class="list1">
              <p>能源行业</p>
              <el-progress :percentage="percentage10" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor4"></el-progress>
              <span>19次</span>
            </div>
          </div>
        </div>
        <div class="right1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="" />
              <p>数据供应商月度访问TOP5</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="" />
            </div>
          </div>
          <div class="right1Con">
            <div class="list1">
              <p>中国移动</p>
              <el-progress :percentage="percentage11" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor"></el-progress>
              <span>65次</span>
            </div>
            <div class="list1">
              <p>浙江电力</p>
              <el-progress :percentage="percentage12" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor1"></el-progress>
              <span>49次</span>
            </div>
            <div class="list1">
              <p>杭州大数据</p>
              <el-progress :percentage="percentage13" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor2"></el-progress>
              <span>41次</span>
            </div>
            <div class="list1">
              <p>中航信</p>
              <el-progress :percentage="percentage14" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor3"></el-progress>
              <span>23次</span>
            </div>
            <div class="list1">
              <p>同花顺</p>
              <el-progress :percentage="percentage15" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor4"></el-progress>
              <span>15次</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-link to="/fouth" class="zuozuo" style="opacity: 0.5">
      <img src="../../img/xiangzuo.png" alt="" />
    </router-link>
    <router-link to="/second" class="youyou">
      <img src="../../img/xiangyou.png" alt="" style="opacity: 0.5" />
    </router-link>
  </div>
</template>

<script>
const echarts = require("echarts");
// import $ from 'jquery'
import "echarts-gl";
export default {
  data() {
    return {
      myChart: "",
      myChart1: "",
      myChart2: "",
      customColor: "#FF7224",
      customColor1: "#EACA8F",
      customColor2: "#DC81B6",
      customColor3: "#06B0BB",
      customColor4: "#657FE2",
      bgcolor: "#041737",
      hangye: [
        {
          name: "金融行业",
          value: "222",
          itemStyle: {
            color: "#EE567F",
          },
        },
        {
          name: "传媒行业",
          value: "334",
          itemStyle: {
            color: "#8180C9",
          },
        },
        {
          name: "教育行业",
          value: "427",
          itemStyle: {
            color: "#5662EE",
          },
        },
        {
          name: "其他",
          value: "156",
          itemStyle: {
            color: "#24E8FF",
          },
        },
        {
          name: "电力行业",
          value: "217",
          itemStyle: {
            color: "#E5714C",
          },
        },
        {
          name: "通信行业",
          value: "357",
          itemStyle: {
            color: "#E5C545",
          },
        },
        {
          name: "工业制造",
          value: "139",
          itemStyle: {
            color: "#23EBC0",
          },
        },
      ],
      hangye1: [
        {
          name: "金融行业",
          value: "222",
          itemStyle: {
            color: "rgba(238, 86, 127, 0.3)",
          },
        },
        {
          name: "传媒行业",
          value: "334",
          itemStyle: {
            color: "rgba(129, 128, 201,0.3)",
          },
        },
        {
          name: "教育行业",
          value: "427",
          itemStyle: {
            color: "rgba(86, 98, 238,0.3)",
          },
        },
        {
          name: "其他",
          value: "156",
          itemStyle: {
            color: "rgba(36, 232, 255,0.3)",
          },
        },
        {
          name: "电力行业",
          value: "217",
          itemStyle: {
            color: "rgba(229, 113, 76,0.3)",
          },
        },
        {
          name: "通信行业",
          value: "357",
          itemStyle: {
            color: "rgba(229, 197, 69,0.3)",
          },
        },
        {
          name: "工业制造",
          value: "139",
          itemStyle: {
            color: "rgba(35, 235, 192,0.3)",
          },
        },
      ],
      leixing: [
        {
          name: "数据集",
          value: "333",
          itemStyle: {
            color: "#8180C9",
          },
        },
        {
          name: "模型服务",
          value: "252",
          itemStyle: {
            color: "#EE567F",
          },
        },
        {
          name: "SaaS服务",
          value: "156",
          itemStyle: {
            color: "#24E8FF",
          },
        },
        {
          name: "数据api",
          value: "339",
          itemStyle: {
            color: "#FF7224",
          },
        },
        {
          name: "数据报告",
          value: "252",
          itemStyle: {
            color: "#E5C545",
          },
        },
        {
          name: "其他",
          value: "416",
          itemStyle: {
            color: "#23EBC0",
          },
        },
      ],
      leixing1: [
        {
          name: "数据集",
          value: "333",
          itemStyle: {
            color: "rgba(129, 128, 201,0.3)",
          },
        },
        {
          name: "模型服务",
          value: "252",
          itemStyle: {
            color: "rgba(238, 86, 127,0.3)",
          },
        },
        {
          name: "SaaS服务",
          value: "156",
          itemStyle: {
            color: "rgba(36, 232, 255,0.3)",
          },
        },
        {
          name: "数据api",
          value: "339",
          itemStyle: {
            color: "rgba(255, 114, 36,0.3)",
          },
        },
        {
          name: "数据报告",
          value: "252",
          itemStyle: {
            color: "rgba(229, 197, 69,0.3)",
          },
        },
        {
          name: "其他",
          value: "416",
          itemStyle: {
            color: "rgba(35, 235, 192,0.3)",
          },
        },
      ],
      options: [
        {
          value: "选项1",
          label: "金融行业数据分级分类标准",
        },
        {
          value: "选项2",
          label: "通信行业数据分级分类标准",
        },
        {
          value: "选项3",
          label: "公共数据分级分类标准",
        },
      ],
      options1: [
        {
          value: "选项1",
          label: "纺织服饰",
        },
        {
          value: "选项2",
          label: "汽车智造",
        },
        {
          value: "选项3",
          label: "金融",
        },
        {
          value: "选项4",
          label: "通信",
        },
        {
          value: "选项5",
          label: "政务",
        },
        {
          value: "选项6",
          label: "其他",
        },
      ],
      options2: [
        {
          value: "选项1",
          label: "L1",
        },
        {
          value: "选项2",
          label: "L2",
        },
        {
          value: "选项3",
          label: "L3",
        },
        {
          value: "选项4",
          label: "L4",
        },
        {
          value: "选项5",
          label: "L5",
        },
      ],
      options3: [
        {
          value: "选项1",
          label: "TXT",
        },
        {
          value: "选项2",
          label: "CSV",
        },
        {
          value: "选项3",
          label: "MYSQL",
        },
        {
          value: "选项4",
          label: "其他",
        },
      ],
      options4: [
        {
          value: "选项1",
          label: "反欺诈",
        },
        {
          value: "选项2",
          label: "工业",
        },
        {
          value: "选项3",
          label: "营销",
        },
        {
          value: "选项4",
          label: "汽车",
        },
        {
          value: "选项5",
          label: "研发",
        },
        {
          value: "选项6",
          label: "其他",
        },
      ],
      value: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      input3: "",
      percentage1: 0,
      percentage2: 0,
      percentage3: 0,
      percentage4: 0,
      percentage5: 0,
      percentage6: 0,
      percentage7: 0,
      percentage8: 0,
      percentage9: 0,
      percentage10: 0,
      percentage11: 0,
      percentage12: 0,
      percentage13: 0,
      percentage14: 0,
      percentage15: 0,
      img1: require("@/img/index/index1.png"),
      img2: require("../../img/index/index2.png"),
      img3: require("../../img/index/index3.png"),
      img4: require("../../img/index/index4.png"),

      dotsData: [],
      dotElements: [],
      moveStep: 1, // 移动步长
      moveDelay: 1, // 移动延迟时间

      width: 1000,
      height: 600,
      points: [],
      connections: [],
      sjdata: [
        { value: 1000, label: "汽车产业" },
        { value: 20, label: "智能制造" },
        { value: 30, label: "生物制造" },
        { value: 40, label: "平台管理" },
        { value: 50, label: "外部数据" },
        { value: 60, label: "通信" },
        { value: 70, label: "平台运营" },
        { value: 80, label: "金属冶炼" },
        { value: 90, label: "运维数据" },
        { value: 100, label: "管理数据" },
        { value: 100, label: "研发数据" },
        { value: 100, label: "库存数据" },
        { value: 100, label: "采购数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
      ],

      newshuzu: [
        {
          name: "汽车产业",
          children: [
            {
              name: "生产数据",
            },
            {
              name: "平台运营",
            },
            {
              name: "运维数据",
            },
            {
              name: "平台运营",
            },
            {
              name: "外部数据",
            },
            {
              name: "管理数据",
            },
          ],
        },
        {
          name: "平台管理",
          children: [
            {
              name: "企业基础数据",
            },
            {
              name: "设备资产信息",
            },
            {
              name: "业务统计数据",
            },
            {
              name: "客户与产品信息",
            },
            {
              name: "产品供应链数据",
              children1: [
                {
                  name: "生产数据",
                },
                {
                  name: "采购数据",
                },
                {
                  name: "库存数据",
                },
                {
                  name: "生产计划",
                },
              ],
            },
          ],
        },
        {
          name: "智能制造",
          children: [
            {
              name: "金属冶炼",
            },
            {
              name: "生物制造",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.getecharts(this.hangye, "echarts");
    this.getecharts1(this.leixing, "echarts1");
    // this.getfu();
    setTimeout(() => {
      this.startAnimation();
    }, 100);

    // this.generateDots();
    // this.renderDots();
    // this.moveDots();

    //第二种方法
    // this.initPoints();
    // this.animatePoints();
    //第三种方法
    this.onBullConnect();


  },
  methods: {
    generateDots() {
      this.dotsData = this.getDots(30);
    },
    renderDots() {
      const container = this.$refs.canvasContainer;
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("width", "100%");
      svg.setAttribute("height", "100%");

      this.dotsData.forEach((dot, index) => {
        const dotElement = document.createElement("div");
        dotElement.classList.add("dot");
        dotElement.style.position = "absolute";
        dotElement.style.width = `${dot.size}px`;
        dotElement.style.height = `${dot.size}px`;
        dotElement.style.borderRadius = "50%";
        dotElement.style.background = dot.gradientColor;
        dotElement.style.left = `${dot.x}px`;
        dotElement.style.top = `${dot.y}px`;
        dotElement.style.transition = "all 1s ease";
        dotElement.style.display = "flex";
        dotElement.style.color = "#fff";
        dotElement.style.flexDirection = "column";
        dotElement.style.alignItems = "center";
        dotElement.style.justifyContent = "center";
        dotElement.dataset.index = index;

        const upperLabel = document.createElement("span");
        upperLabel.textContent = `${dot.label}`;
        upperLabel.style.fontSize = "13px";
        dotElement.appendChild(upperLabel);

        const lowerLabel = document.createElement("span");
        lowerLabel.textContent = `${dot.value}`;
        lowerLabel.style.fontSize = "15px";
        dotElement.appendChild(lowerLabel);

        container.appendChild(dotElement);
        this.dotElements.push(dotElement);

        // 绘制点之间的连线
        // const otherDot = this.dotsData[dot.connectedDotIndex];
        // const line = document.createElementNS(
        //   "http://www.w3.org/2000/svg",
        //   "line"
        // );

        // line.setAttribute("x1", dot.x + dot.size / 2);
        // line.setAttribute("y1", dot.y + dot.size / 2);
        // line.setAttribute("x2", otherDot.x + otherDot.size / 2);
        // line.setAttribute("y2", otherDot.y + otherDot.size / 2);
        // line.setAttribute("stroke", "#f00");
        // // container.appendChild(line);
        // svg.appendChild(line);
      });
      container.appendChild(svg);
    },

    moveDots() {
      const containerWidth = this.$refs.canvasContainer.offsetWidth;
      const containerHeight = this.$refs.canvasContainer.offsetHeight;
      setInterval(() => {
        this.dotsData.forEach((dot, index) => {
          this.moveDot(dot, index, containerWidth, containerHeight);
        });
      }, 3000); // 设置一个较短的时间间隔来持续移动
    },
    moveDot(dot, index, containerWidth, containerHeight) {
      const dotElement = this.dotElements[index];
      const targetX = Math.random() * containerWidth;
      const targetY = Math.random() * containerHeight;
      const duration = 10000; // 移动动画持续时间（毫秒）
      const startTime = performance.now(); // 获取动画开始时间
      const startX = dot.x;
      const startY = dot.y;

      let that = this;
      const easeInOut = (t) => {
        const c = 10;
        return 0.5 * (1 - Math.cos(Math.PI * t)) * (1 - c) + t * c;
      };

      function animate(currentTime) {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // 动画进度
        const easeProgress = easeInOut(progress); // 使用缓动函数
        const newX = startX + (targetX - startX) * easeProgress;
        const newY = startY + (targetY - startY) * easeProgress;

        dot.x = newX;
        dot.y = newY;
        dotElement.style.left = `${newX}px`;
        dotElement.style.top = `${newY}px`;

        // 更新连接到该球体的线条位置
        // that.updateConnectedLines(dot);

        if (progress < 1) {
          requestAnimationFrame(animate); // 继续动画直到进度为1
        } else {
          // 动画完成后重新开始
          that.moveDot(dot, index, containerWidth, containerHeight);
        }
      }

      requestAnimationFrame(animate); // 开始动画
    },

    updateConnectedLines(dot) {
      const svg = this.$refs.canvasContainer.querySelector("svg");
      const lines = svg.querySelectorAll("line");

      lines.forEach((line, i) => {
        // 找到连接到当前球体的线条
        if (i === dot.connectedDotIndex) {
          const connectedDot = this.dotsData[dot.connectedDotIndex];
          const angle = Math.atan2(
            connectedDot.y - dot.y,
            connectedDot.x - dot.x
          );
          const newX = dot.x + (dot.size / 2) * Math.cos(angle);
          const newY = dot.y + (dot.size / 2) * Math.sin(angle);

          line.setAttribute("x1", newX);
          line.setAttribute("y1", newY);
          line.setAttribute("x2", connectedDot.x + connectedDot.size / 2);
          line.setAttribute("y2", connectedDot.y + connectedDot.size / 2);
        }
      });
    },

    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getDots(dotNum) {
      const data = [
        { value: 1000, label: "汽车产业" },
        { value: 20, label: "智能制造" },
        { value: 30, label: "生物制造" },
        { value: 40, label: "平台管理" },
        { value: 50, label: "外部数据" },
        { value: 60, label: "通信" },
        { value: 70, label: "平台运营" },
        { value: 80, label: "金属冶炼" },
        { value: 90, label: "运维数据" },
        { value: 100, label: "管理数据" },
        { value: 100, label: "研发数据" },
        { value: 100, label: "库存数据" },
        { value: 100, label: "采购数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
        { value: 100, label: "生产数据" },
      ];
      const gradientColors = [
        "linear-gradient(to bottom, #1EC4CF, #2D8BA5)", // 渐变色1
        "linear-gradient(to bottom, #90C5EC, #768CF0)", // 渐变色2
        "linear-gradient(to bottom, #FAD9A0, #FA945B)", // 渐变色3
        "linear-gradient(to bottom, #C287DE, #C287DE)", // 渐变色3
        // 添加更多渐变色
      ];
      const origin = [];
      for (let i = 0; i < dotNum; i++) {
        const size = Math.floor(Math.random() * 50) + 50; // 修改大小范围
        const randomGradientIndex = Math.floor(
          Math.random() * gradientColors.length
        ); // 随机选择渐变色
        const gradientColor = gradientColors[randomGradientIndex]; // 获取随机渐变颜色

        const connectedDotIndex = Math.floor(Math.random() * dotNum); // 连接的点的索引
        // let x, y;
        // do {
        //   x = Math.random() * this.$refs.canvasContainer.offsetWidth;
        //   y = Math.random() * this.$refs.canvasContainer.offsetHeight;
        // } while (this.isOverlap(origin, x, y, size)); // 检查是否重叠

        origin.push({
          size: size, // 不同大小
          x: Math.random() * this.$refs.canvasContainer.offsetWidth,
          y: Math.random() * this.$refs.canvasContainer.offsetHeight,
          // data: data[i].label,
          value: data[i].value, // 设置 value 属性为 1000
          label: data[i % data.length].label, // 使用数据中的标签
          gradientColor: gradientColor, // 添加渐变颜色
          connectedDotIndex: connectedDotIndex, // 存储连接的点的索引
        });
      }
      return origin;
    },
    // isOverlap(dots, x, y, size) {
    //   // 检查新圆点和已有圆点是否重叠
    //   for (let dot of dots) {
    //     const dx = x - dot.x;
    //     const dy = y - dot.y;
    //     const distance = Math.sqrt(dx * dx + dy * dy);
    //     if (distance < size + dot.size) {
    //       return true; // 重叠
    //     }
    //   }
    //   return false; // 不重叠
    // },

    getecharts(data, id) {
      this.myChart = echarts.init(document.getElementById(id));
      var option = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },
        series: [
          {
            name: "行业",
            type: "pie",
            z: 10,
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 0,
            },
            label: {
              show: true,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
          {
            name: "",
            type: "pie",
            tooltip: {
              show: false,
            },
            z: 8,
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: false,
            },
            data: this.hangye1,
          },
        ],
      };
      this.myChart.setOption(option);
    },
    getecharts1(data, id) {
      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },

        series: [
          {
            name: "类型",
            type: "pie",
            z: 10,
            radius: ["40%", "60%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 5,
            },
            label: {
              show: true,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
          {
            name: "类型",
            type: "pie",
            tooltip: {
              show: false,
            },
            z: 8,
            radius: ["40%", "70%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: false,
            },
            data: this.leixing1,
          },
        ],
      };
      this.myChart1.setOption(option1);
    },
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.myChart1.resize();
        this.myChart.resize();
        // this.getfu()
        // this.myChart2.resize();
      }, 200);
    },
    startAnimation() {
      // 设置动画效果，假设从 0% 到 100% 的动画
      this.percentage1 = 98;
      this.percentage2 = 80;
      this.percentage3 = 60;
      this.percentage4 = 50;
      this.percentage5 = 30;
      this.percentage6 = 80;
      this.percentage7 = 70;
      this.percentage8 = 50;
      this.percentage9 = 40;
      this.percentage10 = 20;
      this.percentage11 = 80;
      this.percentage12 = 60;
      this.percentage13 = 50;
      this.percentage14 = 40;
      this.percentage15 = 20;
    },

    // 第二种方法

    initPoints() {
      const numPoints = 20;
      for (let i = 0; i < numPoints; i++) {
        this.points.push({
          x: Math.random() * this.width,
          y: Math.random() * this.height,
          dx: (Math.random() - 0.5) * 2, // Random speed in x direction
          dy: (Math.random() - 0.5) * 2, // Random speed in y direction
        });
      }

      // Create connections between all points
      for (let i = 0; i < numPoints; i++) {
        for (let j = i + 1; j < numPoints; j++) {
          this.connections.push({
            start: this.points[i],
            end: this.points[j],
          });
        }
      }
    },
    animatePoints() {
      const animate = () => {
        this.points.forEach((point) => {
          point.x += point.dx;
          point.y += point.dy;

          // Wrap around the edges
          if (point.x < 0) {
            point.x = this.width;
          } else if (point.x > this.width) {
            point.x = 0;
          }
          if (point.y < 0) {
            point.y = this.height;
          } else if (point.y > this.height) {
            point.y = 0;
          }
        });

        // Update the connections array to reflect the new points positions
        this.connections = this.points.flatMap((point, index) =>
          this.points.slice(index + 1).map((otherPoint) => ({
            start: point,
            end: otherPoint,
          }))
        );

        this.$forceUpdate(); // Force Vue to update the DOM
        requestAnimationFrame(animate); // Schedule the next frame
      };

      animate();
    },

    //第二中方法结束

    //第三种方法

    // onBullConnect() {
    //   const canvas = this.$refs.myCanvas;
    //   const ctx = canvas.getContext("2d");
    //   const ballArr = [];
    //   const colors = ["#6F94E0", "#1095A8", "#EA9F65", "#C989DA"]; // 固定颜色数组
    //   const data = [
    //     { value: 1000, label: "汽车产业" },
    //     { value: 20, label: "智能制造" },
    //     { value: 30, label: "生物制造" },
    //     { value: 40, label: "平台管理" },
    //     { value: 50, label: "外部数据" },
    //     { value: 60, label: "通信" },
    //     { value: 70, label: "平台运营" },
    //     { value: 80, label: "金属冶炼" },
    //     { value: 90, label: "运维数据" },
    //     { value: 100, label: "管理数据" },
    //     { value: 100, label: "研发数据" },
    //     { value: 100, label: "库存数据" },
    //     { value: 100, label: "采购数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //     { value: 100, label: "生产数据" },
    //   ];
    //   // 创建四个渐变颜色
    //   const gradient1 = ctx.createLinearGradient(0, 0, canvas.width, 0);
    //   gradient1.addColorStop(0, '#7FB6DE');
    //   gradient1.addColorStop(1, '#647CE1');

    //   const gradient2 = ctx.createLinearGradient(0, 0, canvas.width, 0);
    //   gradient2.addColorStop(0, '#EACA90');
    //   gradient2.addColorStop(1, '#EA7E43');

    //   const gradient3 = ctx.createLinearGradient(0, 0, canvas.width, 0);
    //   gradient3.addColorStop(0, '#DB95D6');
    //   gradient3.addColorStop(1, '#A973E1');

    //   const gradient4 = ctx.createLinearGradient(0, 0, canvas.width, 0);
    //   gradient4.addColorStop(0, '#71D5D0');
    //   gradient4.addColorStop(1, '#4DD0B3');

    //   function Ball() {
    //     this.x = parseInt(Math.random() * canvas.width);
    //     this.y = parseInt(Math.random() * canvas.height);
    //     this.r = Math.random() * 40 + 15; // Random size between 10 and 40
    //     this.color = colors[Math.floor(Math.random() * colors.length)];
    //     this.dx = Math.random() * 10 - 5;
    //     this.dy = Math.random() * 10 - 5;
    //     ballArr.push(this);

    //     this.index = ballArr.length - 1;

    //     this.value = data[Math.floor(Math.random() * data.length)].value; // 随机生成一个数值
    //     this.label = data[Math.floor(Math.random() * data.length)].label; // 随机选择一个标签
    //   }

    //   Ball.prototype.update = function() {
    //   this.x += this.dx;
    //   this.y += this.dy;

    //   // 边界判断条件调整为小球中心点的坐标加上半径
    //   if (this.x - this.r < 0) {
    //     this.x = this.r + 1; // 避免完全贴边，给一个偏移量
    //     this.dx = -this.dx; // 反弹
    //   }
    //   if (this.x + this.r > canvas.width) {
    //     this.x = canvas.width - this.r - 1; // 避免完全贴边，给一个偏移量
    //     this.dx = -this.dx; // 反弹
    //   }
    //   if (this.y - this.r < 0) {
    //     this.y = this.r + 1; // 避免完全贴边，给一个偏移量
    //     this.dy = -this.dy; // 反弹
    //   }
    //   if (this.y + this.r > canvas.height) {
    //     this.y = canvas.height - this.r - 1; // 避免完全贴边，给一个偏移量
    //     this.dy = -this.dy; // 反弹
    //   }
    // };

    //   Ball.prototype.render = function() {

    //     let gradient;
    //     if (this.index % 4 === 0) {
    //       gradient = gradient1;
    //     } else if (this.index % 4 === 1) {
    //       gradient = gradient2;
    //     } else if (this.index % 4 === 2) {
    //       gradient = gradient3;
    //     } else {
    //       gradient = gradient4;
    //     }

    //     ctx.beginPath();
    //     ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2);
    //     ctx.fillStyle = gradient;
    //     ctx.fill();
    //     ctx.fillStyle = "white"; // Text color
    //     // ctx.fillText("Text1", this.x - 15, this.y); // Text1
    //     // ctx.fillText("Text2", this.x - 15, this.y + 20); // Text2
    //     ctx.font = "15px Arial"; // 设置字体大小和类型
    //     const text1X = this.x - ctx.measureText(this.label).width / 2;
    //     const text1Y = this.y - 5;
    //     const text2X = this.x - ctx.measureText(this.value).width / 2;
    //     const text2Y = this.y + 10; // 垂直间隔为字体大小的高度
    //     ctx.fillText(this.label, text1X, text1Y); // 渲染标签
    //     ctx.fillText(this.value, text2X, text2Y); // 渲染数字

    //     // 绘制球体周围的虚线边
    //     ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)'; // 设置虚线边颜色
    //     ctx.setLineDash([5, 5]); // 设置虚线样式
    //     ctx.beginPath();
    //     ctx.arc(this.x, this.y, this.r + 10, 0, Math.PI * 2); // 半径比球体大一些
    //     ctx.stroke();
    //     ctx.closePath();

    //     // ctx.fillText(this.value, this.x - 15, this.y); // 渲染数字
    //     // ctx.fillText(this.label, this.x - 15, this.y + 20); // 渲染标签
    //   };

    //   Ball.prototype.drawConnections = function() {
    //     for (let i = this.index; i < ballArr.length; i++) {
    //       if (
    //         Math.abs(ballArr[i].x - this.x) < 150 &&
    //         Math.abs(ballArr[i].y - this.y) < 150 &&
    //         ballArr[i] !== this
    //       ) {
    //         ctx.strokeStyle = "#009F6A"; // Set line color to blue
    //         ctx.setLineDash([5, 5]); // Set line style to dashed
    //         ctx.beginPath();
    //         ctx.moveTo(this.x, this.y);
    //         ctx.lineTo(ballArr[i].x, ballArr[i].y);
    //         ctx.closePath();
    //         ctx.stroke();
    //       }
    //     }
    //   };

    //   for (let i = 0; i < 20; i++) {
    //     new Ball();
    //   }

    //   setInterval(function() {
    //     ctx.clearRect(0, 0, canvas.width, canvas.height);
    //     for (let i = 0; i < ballArr.length; i++) {
    //       ballArr[i].update();
    //       ballArr[i].drawConnections();
    //     }
    //     for (let i = 0; i < ballArr.length; i++) {
    //       ballArr[i].render();
    //     }
    //   }, 40);
    // }

    //   onBullConnect() {
    // const canvas = this.$refs.myCanvas;
    // const ctx = canvas.getContext("2d");
    // const balls = [];

    // // 定义大球和小球的颜色数组
    // const bigBallColors = ["#6F94E0", "#EA9F65", "#C989DA"];
    // const smallBallColors = ["#1095A8", "#EA7E43", "#71D5D0"];

    // // 定义大球和小球的半径
    // const bigBallRadius = 50;
    // const smallBallRadius = 20;

    // // 定义大球的数量
    // const numberOfBigBalls = 3;

    // // 定义每个大球带着的小球数量
    // const numberOfSmallBalls = 6;

    // // 定义大球和小球的移动速度
    // const bigBallSpeed = 2;
    // const smallBallSpeed = 4;

    // // 定义数据
    // const data = [
    //   {
    //     name: "汽车产业",
    //     children: [
    //       { name: "生产数据" },
    //       { name: "平台运营" },
    //       { name: "运维数据" },
    //       { name: "平台运营" },
    //       { name: "外部数据" },
    //       { name: "管理数据" },
    //     ],
    //   },
    //   {
    //     name: "平台管理",
    //     children: [
    //       { name: "企业基础数据" },
    //       { name: "设备资产信息" },
    //       { name: "业务统计数据" },
    //       { name: "客户与产品信息" },
    //       {
    //         name: "产品供应链数据",
    //         children1: [
    //           { name: "生产数据" },
    //           { name: "采购数据" },
    //           { name: "库存数据" },
    //           { name: "生产计划" },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     name: "智能制造",
    //     children: [
    //       { name: "金属冶炼" },
    //       { name: "生物制造" },
    //     ],
    //   },
    // ];

    // // 创建大球和小球对象
    // for (let i = 0; i < numberOfBigBalls; i++) {
    //   const bigBall = {
    //     x: Math.random() * canvas.width,
    //     y: Math.random() * canvas.height,
    //     dx: Math.random() * bigBallSpeed * 2 - bigBallSpeed,
    //     dy: Math.random() * bigBallSpeed * 2 - bigBallSpeed,
    //     color: bigBallColors[i],
    //     name: data[i].name,
    //     children: [],
    //   };

    //   // 创建小球并将其与大球关联
    //   for (let j = 0; j < numberOfSmallBalls; j++) {
    //     const smallBall = {
    //       x: bigBall.x + Math.random() * 50 - 25,
    //       y: bigBall.y + Math.random() * 50 - 25,
    //       dx: Math.random() * smallBallSpeed * 2 - smallBallSpeed,
    //       dy: Math.random() * smallBallSpeed * 2 - smallBallSpeed,
    //       color: smallBallColors[j % smallBallColors.length],
    //       name: data[i].children[j % data[i].children.length].name,
    //     };
    //     bigBall.children.push(smallBall);
    //   }

    //   balls.push(bigBall);
    // }

    // // 绘制函数
    // function draw() {
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);

    //   // 绘制大球和小球
    //   balls.forEach((bigBall) => {
    //     ctx.beginPath();
    //     ctx.arc(bigBall.x, bigBall.y, bigBallRadius, 0, Math.PI * 2);
    //     ctx.fillStyle = bigBall.color;
    //     ctx.fill();

    //     // 绘制大球与大球之间的连线
    //     balls.filter(ball => ball !== bigBall).forEach(otherBigBall => {
    //       ctx.strokeStyle = "#009F6A"; // 设置线条颜色为绿色
    //       ctx.setLineDash([5, 5]); // 设置线条样式为虚线
    //       ctx.beginPath();
    //       ctx.moveTo(bigBall.x, bigBall.y);
    //       ctx.lineTo(otherBigBall.x, otherBigBall.y);
    //       ctx.stroke();
    //     });

    //     bigBall.children.forEach((smallBall) => {
    //       ctx.beginPath();
    //       ctx.arc(smallBall.x, smallBall.y, smallBallRadius, 0, Math.PI * 2);
    //       ctx.fillStyle = smallBall.color;
    //       ctx.fill();

    //       // 绘制大球和小球之间的连线
    //       ctx.strokeStyle = "#009F6A"; // 设置线条颜色为绿色
    //       ctx.setLineDash([5, 5]); // 设置线条样式为虚线
    //       ctx.beginPath();
    //       ctx.moveTo(bigBall.x, bigBall.y);
    //       ctx.lineTo(smallBall.x, smallBall.y);
    //       ctx.stroke();
    //     });
    //   });
    // }

    // // 更新函数
    // function update() {
    //   balls.forEach((bigBall) => {
    //     // 更新大球的位置
    //     bigBall.x += bigBall.dx;
    //     bigBall.y += bigBall.dy;

    //     // 更新小球的位置
    //     bigBall.children.forEach((smallBall) => {
    //       smallBall.x += smallBall.dx;
    //       smallBall.y += smallBall.dy;
    //     });

    //     // 处理边界碰撞
    //     if (bigBall.x + bigBallRadius > canvas.width || bigBall.x - bigBallRadius < 0) {
    //       bigBall.dx *= -1;
    //     }
    //     if (bigBall.y + bigBallRadius > canvas.height || bigBall.y - bigBallRadius < 0) {
    //       bigBall.dy *= -1;
    //     }

    //     // 处理小球边界碰撞
    //     bigBall.children.forEach((smallBall) => {
    //       if (smallBall.x + smallBallRadius > canvas.width || smallBall.x - smallBallRadius < 0) {
    //         smallBall.dx *= -1;
    //       }
    //       if (smallBall.y + smallBallRadius > canvas.height || smallBall.y - smallBallRadius < 0) {
    //         smallBall.dy *= -1;
    //       }
    //     });
    //   });
    // }

    // // 渲染和更新循环
    // setInterval(() => {
    //   draw();
    //   update();
    // }, 40);
    //   }


    onBullConnect() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");
      const balls = [];

      const gradients = [
        ['#7FB6DE', '#647BE1'], // 渐变组1
        ['#EACA92', '#EB844C'], // 渐变组2
        ['#72D5D1', '#4ED1B4'], // 渐变组2 
        ['#06B0BB', '#1B7794'], // 渐变组2 
        ['#DB95D6', '#AD76E1'], // 渐变组2 
        // 添加更多渐变组
      ];

      // 创建渐变函数
      function createGradient(x1, y1, x2, y2, gradientColors) {
        const gradient = ctx.createLinearGradient(x1, y1, x2, y2);
        gradient.addColorStop(0, gradientColors[0]);
        gradient.addColorStop(1, gradientColors[1]);
        return gradient;
      }


      // 定义大球和小球的颜色数组
      const bigBallColors = ["#6F94E0", "#EA9F65", "#C989DA", "#5DD4C2", "#EA9F65", "#6F94E0"];
      const smallBallColors = ["#1095A8", "#EA7E43", "#71D5D0", "#1095A8", "#EA7E43", "#71D5D0"];

      // 定义大球和小球的半径
      const bigBallRadius = 50;
      const smallBallRadius = 30;

      // 定义大球的数量
      const numberOfBigBalls = 6;

      // 定义每个大球带着的小球数量
      // const numberOfSmallBalls = 6;

      // 定义大球和小球的移动速度
      const bigBallSpeed = 1;
      const smallBallSpeed = 2;

      // 定义数据
      const data = [
        {
          name: "汽车产业",
          children: [
            { name: "生产数据" },
            { name: "平台运营" },
            { name: "运维数据" },
            { name: "平台运营" },
            { name: "外部数据" },
            { name: "管理数据" },
          ],
        },
        {
          name: "平台管理",
          children: [
            { name: "企业基础数据" },
            { name: "设备资产信息" },
            { name: "业务统计数据" },
            { name: "客户与产品信息" },

          ],
        },
        {
          name: "产品供应链数据",
          children1: [
            { name: "生产数据" },
            { name: "采购数据" },
            { name: "库存数据" },
            { name: "生产计划" },
          ],
        },
        {
          name: "智能制造",
          children: [
            { name: "金属冶炼" },
            { name: "生物制造" },
          ],
        },
        {
          name: "通信",
          children: [
            { name: "平台运营" },
            { name: "平台管理" },
            { name: "外部数据" },
            { name: "管理数据" },
            { name: "运维数据" },
            { name: "生产数据" },
            { name: "研发数据" },
          ],
        },
        {
          name: "金融",
          children: [
            { name: "平台运营" },
            { name: "平台管理" },
            { name: "外部数据" },
            { name: "管理数据" },
            { name: "运维数据" },
            { name: "生产数据" },
            { name: "研发数据" },
          ],
        },
        {
          name: "反欺诈",
          children: [

          ],
        },
      ];


      // 创建大球和小球对象
      // for (let i = 0; i < numberOfBigBalls; i++) {
      //   const bigBall = {
      //     x: Math.random() * canvas.width,
      //     y: Math.random() * canvas.height,
      //     dx: Math.random() * bigBallSpeed * 2 - bigBallSpeed,
      //     dy: Math.random() * bigBallSpeed * 2 - bigBallSpeed,
      //     color: bigBallColors[i],
      //     name: data[i].name,
      //     children: [],
      //   };

      //   // 创建小球并将其与大球关联
      //   const childrenData = data[i].children || [];
      //   for (let j = 0; j < numberOfSmallBalls; j++) {
      //     const smallBall = {
      //       x: bigBall.x + Math.random() * 50 - 25,
      //       y: bigBall.y + Math.random() * 50 - 25,
      //       dx: Math.random() * smallBallSpeed * 2 - smallBallSpeed,
      //       dy: Math.random() * smallBallSpeed * 2 - smallBallSpeed,
      //       color: smallBallColors[j % smallBallColors.length],
      //       name: childrenData[j % childrenData.length]?.name || "", // 使用可选链运算符来安全访问属性
      //     };
      //     bigBall.children.push(smallBall);
      //   }

      //   balls.push(bigBall);
      // }


      for (let i = 0; i < numberOfBigBalls; i++) {
        // 确保 data[i] 存在且具有 children 属性
        if (data[i] && data[i].children) {
          const bigBall = {
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            dx: Math.random() * bigBallSpeed * 2 - bigBallSpeed,
            dy: Math.random() * bigBallSpeed * 2 - bigBallSpeed,
            color: bigBallColors[i % bigBallColors.length],
            name: data[i].name,
            children: [],
          };

          // 创建小球并将其与大球关联
          for (let j = 0; j < data[i].children.length; j++) {
            const smallBall = {
              x: bigBall.x + Math.random() * 50 - 25,
              y: bigBall.y + Math.random() * 50 - 25,
              dx: Math.random() * smallBallSpeed * 2 - smallBallSpeed,
              dy: Math.random() * smallBallSpeed * 2 - smallBallSpeed,
              color: smallBallColors[j % smallBallColors.length],
              name: data[i].children[j].name,
            };
            bigBall.children.push(smallBall);
          }

          balls.push(bigBall);
        }
      }




      // 绘制函数
      function draw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // 绘制连线
        ctx.strokeStyle = "#009F6A"; // 设置线条颜色为绿色
        ctx.setLineDash([5, 5]); // 设置线条样式为虚线

        for (let i = 0; i < balls.length; i++) {
          const bigBall = balls[i];
          for (let j = 0; j < bigBall.children.length; j++) {
            const smallBall = bigBall.children[j];
            ctx.beginPath();
            ctx.moveTo(bigBall.x, bigBall.y);
            ctx.lineTo(smallBall.x, smallBall.y);
            ctx.stroke();
          }
        }

        // 绘制球体和文字
        for (let i = 0; i < balls.length; i++) {
          const bigBall = balls[i];
          // 绘制大球的外层虚线
          ctx.beginPath();
          ctx.arc(bigBall.x, bigBall.y, bigBallRadius + 5, 0, Math.PI * 2);
          ctx.strokeStyle = "rgba(255, 255, 255, 0.5)"; // 设置虚线颜色
          ctx.setLineDash([5, 5]); // 设置虚线样式
          ctx.stroke();
          ctx.closePath();

          const gradientColors = gradients[i % gradients.length];
          const bigGradient = createGradient(bigBall.x, bigBall.y - bigBallRadius, bigBall.x, bigBall.y + bigBallRadius, gradientColors);

          // 绘制大球
          ctx.beginPath();
          ctx.arc(bigBall.x, bigBall.y, bigBallRadius, 0, Math.PI * 2);
          // ctx.fillStyle = bigBall.color;
          ctx.fillStyle = bigGradient;
          ctx.fill();
          ctx.closePath();

          // 绘制大球的文字
          ctx.fillStyle = "white";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "14px Arial";
          ctx.fillText(bigBall.name, bigBall.x, bigBall.y);

          // 绘制小球
          for (let j = 0; j < bigBall.children.length; j++) {
            const smallBall = bigBall.children[j];

            // 绘制小球的外层虚线
            ctx.beginPath();
            ctx.arc(smallBall.x, smallBall.y, smallBallRadius + 5, 0, Math.PI * 2);
            ctx.strokeStyle = "rgba(255, 255, 255, 0.5)"; // 设置虚线颜色
            ctx.setLineDash([5, 5]); // 设置虚线样式
            ctx.stroke();
            ctx.closePath();

            // 绘制小球
            ctx.beginPath();
            ctx.arc(smallBall.x, smallBall.y, smallBallRadius, 0, Math.PI * 2);
            // ctx.fillStyle = smallBall.color;
            ctx.fillStyle = bigGradient;
            ctx.fill();
            ctx.closePath();

            // 绘制小球的文字
            ctx.fillStyle = "white";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.font = "12px Arial";
            ctx.fillText(smallBall.name, smallBall.x, smallBall.y);
          }
        }
      }



      // 更新函数
      function update() {
        balls.forEach((bigBall) => {
          // 更新大球的位置
          bigBall.x += bigBall.dx;
          bigBall.y += bigBall.dy;

          // 大球与边界碰撞检测
          if (bigBall.x - bigBallRadius < 0) {
            bigBall.x = bigBallRadius; // 左边界
            bigBall.dx = -bigBall.dx; // 反向移动
          }
          if (bigBall.x + bigBallRadius > canvas.width) {
            bigBall.x = canvas.width - bigBallRadius; // 右边界
            bigBall.dx = -bigBall.dx; // 反向移动
          }
          if (bigBall.y - bigBallRadius < 0) {
            bigBall.y = bigBallRadius; // 上边界
            bigBall.dy = -bigBall.dy; // 反向移动
          }
          if (bigBall.y + bigBallRadius > canvas.height) {
            bigBall.y = canvas.height - bigBallRadius; // 下边界
            bigBall.dy = -bigBall.dy; // 反向移动
          }

          // 更新小球的位置
          bigBall.children.forEach((smallBall) => {
            smallBall.x += smallBall.dx;
            smallBall.y += smallBall.dy;

            // 小球与边界碰撞检测
            if (smallBall.x - smallBallRadius < 0 || smallBall.x + smallBallRadius > canvas.width) {
              smallBall.dx = -smallBall.dx;
            }
            if (smallBall.y - smallBallRadius < 0 || smallBall.y + smallBallRadius > canvas.height) {
              smallBall.dy = -smallBall.dy;
            }
          });
        });
      }

      // 动画循环
      function animate() {
        requestAnimationFrame(animate);
        update();
        draw();
      }

      // 启动动画
      animate();
    }












    //第三种方法结束
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 1%;
  background: url(../../img/bg.png) center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .headerbar {
    margin-bottom: calc(100vh * 20 / 1080);
    width: 100%;
    height: calc(100vh * 100 / 1080);
    background: url(../../img/head_bg.png) center no-repeat;
    background-size: 100% 100%;
    position: relative;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      position: absolute;
      left: 0;
      z-index: 999;
      width: auto;
      height: auto;
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
        width: 130px;
      }
    }

    .zhongjian {
      width: auto;
      height: auto;
      text-align: center;

      p {
        font-size: 2em;
        color: #fff;
      }
    }
  }

  .xuanze {
    position: relative;
    z-index: 99999;
    margin-bottom: calc(100vh * 20 / 1080);
    width: 100%;
    height: calc(100vh * 80 / 1080);
    background: url(../../img/zhong_bg.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1%;

    .xz1 {
      width: 18%;
      height: 44px;
      display: flex;
      align-items: center;

      p {
        font-size: 16px;
        color: #fff;
      }

      .zlist {
        margin-left: 15px;
        width: 70%;
        height: 100%;
        background: #0b3f87;
        cursor: pointer;

        .el-select {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          background: #0b3f87;
          border-radius: 0;

          .el-input__inner {
            font-size: 14px;
            color: #a1b5d1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {
              width: 44px;
              height: 44px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: #032e67;
              font-size: 40px;
            }
          }
        }
      }
    }

    .xz2 {
      width: 44px;
      height: 44px;
      background: #0b3f87;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: auto;
      }
    }
  }

  .content {
    position: relative;
    z-index: 888;
    width: 100%;
    height: calc(100vh * 860 / 1080);
    display: flex;
    justify-content: space-between;

    .left1Top {
      width: 100%;
      height: calc(100vh * 40 / 1080);
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url(../../img/top_bg.png) center no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 3%;

      .left1Top1 {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;

        img {
          width: auto;
          margin-right: 10px;
        }

        p {
          font-size: 1.2rem !important;
          color: #fff;
        }
      }

      .more {
        width: auto;

        img {
          width: auto;
        }
      }
    }

    @keyframes floatUpDown {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-20px);
        /* 上浮的距离 */
      }

      100% {
        transform: translateY(0);
      }
    }

    .conLeft {
      position: relative;
      z-index: 9999;
      width: 21%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 1%;

      .left1 {
        margin-bottom: calc(100vh * 10 / 1080);
        width: 100%;
        height: calc(100vh * 126 / 1080);

        .left1Top {
          width: 100%;
          height: calc(100vh * 40 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: url(../../img/top_bg.png) center no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
          padding: 0 3%;

          .left1Top1 {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;

            img {
              width: auto;
              margin-right: 10px;
            }

            p {
              font-size: 0.8em;
              color: #fff;
            }
          }

          .more {
            width: auto;

            img {
              width: auto;
            }
          }
        }

        .left1Con {
          width: 100%;
          height: calc(100vh * 86 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 3%;
          background: #041736;
          background: url(../../img/bg1.png) center no-repeat;
          background-size: 100% 100%;

          .left1Con1 {
            width: 46%;
            height: auto;
            display: flex;
            align-items: center;
            border-right: 2px solid #fff;

            img {
              width: auto;
              margin-right: 15px;
              animation: floatUpDown 2.5s ease-in-out infinite;
            }

            p {
              font-size: 45px;
              color: #fff;
            }
          }

          .left1Con2 {
            width: 45%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .list1 {
              margin: 5px 0;
              width: 100%;
              height: auto;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              &:nth-child(2) {
                span {
                  background: #168fff;
                }
              }

              span {
                margin-right: 5px;
                width: 60px;
                height: 20px;
                background: #ff9813;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                color: #fff;
              }

              p {
                font-size: 15px;
                color: #fff;
              }
            }
          }
        }
      }

      .left2 {
        margin-bottom: calc(100vh * 10 / 1080);
        width: 100%;
        height: calc(100vh * 270 / 1080);

        .left1Top {
          width: 100%;
          height: calc(100vh * 40 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: url(../../img/top_bg.png) center no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
          padding: 0 3%;

          .left1Top1 {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;

            img {
              width: auto;
              margin-right: 10px;
            }

            p {
              font-size: 0.8em;
              color: #fff;
            }
          }

          .more {
            width: auto;

            img {
              width: auto;
            }
          }
        }

        .left2Con {
          width: 100%;
          height: calc(100vh * 230 / 1080);
          background: url(../../img/bg2.png) center no-repeat;
          background-size: 100% 100%;

          #echarts {
            width: 100%;
            height: 100%;
          }

          #echarts1 {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .conMid {
      position: relative;
      width: 56%;
      height: 100%;

      .dot {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          color: #fff;
        }
      }

      .midTop {
        width: 100%;
        height: 48px;
        position: relative;
        z-index: 8888;
      }

      .midC {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90%;
        z-index: 999;

        #fufu {
          width: 100%;
          height: 100%;
        }

        .fufu {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .conRight {
      width: 21%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 1%;
      position: relative;
      z-index: 9999;

      .right1 {
        margin-bottom: calc(100vh * 10 / 1080);
        width: 100%;
        height: calc(100vh * 270 / 1080);

        .left1Top {
          width: 100%;
          height: calc(100vh * 40 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: url(../../img/top_bg.png) center no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
          padding: 0 3%;

          .left1Top1 {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;

            img {
              width: auto;
              margin-right: 10px;
            }

            p {
              font-size: 0.8em;
              color: #fff;
            }
          }

          .more {
            width: auto;

            img {
              width: auto;
            }
          }
        }

        .right1Con {
          width: 100%;
          height: calc(100vh * 230 / 1080);
          box-sizing: border-box;
          padding: 5%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background: url(../../img/bg2.png) center no-repeat;
          background-size: 100% 100%;

          .list1 {
            width: 100%;
            height: 24%;
            border-bottom: 1px dotted #78b1ff;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 14px;
              color: #fff;
              width: 25%;
              text-align: left;
            }

            span {
              font-size: 16px;
              color: #fff;
              width: 15%;
            }

            .el-progress {
              margin: 0 10px;
              width: 65%;
            }
          }
        }
      }
    }
  }
}
</style>

<style>
.zlist .el-input__inner {
  font-size: 14px;
  color: #a1b5d1;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0b3f87;
  border: none;
}

.zlist .el-input__suffix {
  width: 44px;
  height: 44px;
  background: #032e67;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zlist .el-input__icon {
  width: 44px;
  height: 44px;
}

.zlist .el-select__caret {
  font-size: 30px !important;
}

.list1 .el-progress-bar__outer {
  border-radius: 0 !important;
}

.list1 .el-progress-bar__inner {
  border-radius: 0 !important;
}

.midTop .el-input__inner {
  background: #012775;
  border: 1px solid #c0feff;
  box-sizing: border-box;
  padding: 3%;
}

.midTop .el-button {
  background: #fa7a55 !important;
  color: #fff;
  border: 1px solid #fa7a55 !important;
}

.midTop .el-input-group__append {
  background: #fa7a55 !important;
  border: 1px solid #fa7a55 !important;
}

.midTop .el-icon-search {
  font-size: 18px;
  color: #fff;
}

.midTop span {
  color: #fff;
  font-size: 15px;
}

.el-progress {
  transition: width 0.3s ease;
}
</style>